import { FC, ReactNode, useRef } from 'react';
import ThemeSwitcher from 'react-css-vars';
import { Route } from 'react-router';
import { CSSTransition } from 'react-transition-group';

import { AppModel } from 'models';
import { AssetProvider } from 'context';
import { StoreProvider, singleton } from 'store';
import { LocalizationProvider } from 'localization';

import { Navigation } from 'components/Navigation';

import AppViewModel from '../viewModel';

import routes from 'views/routes';
import { Location } from 'components/Location';


const AppView: FC = () => {
    const model = AppModel();
    const viewModel = AppViewModel(model);

    return (
        <ThemeSwitcher theme={viewModel.getBranding()}>

            <StoreProvider store={singleton}>

                <LocalizationProvider fetchTranslations={viewModel.fetchTranslations}>

                    <AssetProvider assets={viewModel.getAssets()}>

                        <Location>

                            <Navigation model={model} />

                            {model.appInitialized ? <div className='main-body'>

                                {routes.map(({ path, Component }) => {

                                    // const config = model.getConfig();
                                    // const routeEnabled = path === '/' || config.Bedside?.enabledContainers.includes(path.split('/')[1]);
                                    // if (!routeEnabled) {
                                    //     return null;
                                    // }

                                    return (
                                        <Route key={path} exact path={path}>
                                            {({ match }) => (
                                                <SlideWrapper direction={viewModel.getTransitionDirection()} match={match}>
                                                    <Component />
                                                </SlideWrapper>
                                            )}
                                        </Route>
                                    )
                                })}

                            </div> : null}

                        </Location>

                    </AssetProvider>

                </LocalizationProvider>

            </StoreProvider>

        </ThemeSwitcher >
    );
}

export default AppView;

const SlideWrapper: FC<{ children: ReactNode, direction: string, match: any }> = ({ children, direction, match, ...props }) => {
    const nodeRef = useRef(null);
    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={match != null}
            timeout={900}
            classNames={`${direction}-slide`}
            unmountOnExit
            {...props}
        >
            <section className='route-section' ref={nodeRef}>
                {children}
            </section>
        </CSSTransition>
    );
};