import { AppSubstrate, Crypto, DataStore } from '../AppSubstrate';

export class UserAuthentication {

    private store: DataStore;
    private crypto: Crypto;

    constructor(store: DataStore, crypto: Crypto) {
        this.store = store;
        this.crypto = crypto;
    }

    performLogin = async (
        username: string,
        password: string,
        multiFactorTokens: {} | undefined = undefined
    ) => {

        const hashedUsername = await this.crypto.hashString(username);

        const salt = (await this._assertUserSalt(hashedUsername)).payload.salt;

        const { userPassword } = this.crypto.getPasswordDerivedAssets(password, salt);

        const headers = {
            user: hashedUsername,
            pass: userPassword
            // multiFactorTokens
        }

        const AuthenticationRequest = new AppSubstrate();
        const builder = AuthenticationRequest.builder;
        builder.category = 'UserAuthentication';
        builder.headers = headers;
        
        const response = await AuthenticationRequest.run(builder);

        if (response.payload.authenticated) {
            this.store.authenticated = true;
            this.store.userKey = hashedUsername;
            this.store.passwordKey = userPassword
        }

        return response;
    }

    performLogout = () => {
        this.store.authenticated = false;
        this.store.userKey = '';
        this.store.passwordKey = '';
    }

    private _assertUserSalt = (hashedUsername: string) => {
        const UserSaltRequest = new AppSubstrate();
        const builder = UserSaltRequest.builder;
        builder.category = 'GetUserSalt';
        builder.headers = { user: hashedUsername };

        return UserSaltRequest.run(builder);
    }
}