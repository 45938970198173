import React from 'react'

import './Spinner.scss'

type Props = {
    color?: string,
    className?: string,
}

const Spinner = ({ color, className = '' }: Props) => {
    const circles = [...Array(8)].map((_, index) => {
        return (
            <div key={index}>
                <div className={'div-after'} style={{ background: color }}></div>
            </div>
        )
    })

    return (
        <div className={'lds-roller ' + className}>
            { circles}
        </div>
    )
}

export default Spinner;