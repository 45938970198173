import CryptoJS from 'crypto-js';

import { KEY_SERVER, KEY_USER } from './types';

export class Crypto {

    hashString = async (string: string) => {
        return CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
    }

    convertBufferToHex = (buffer: Uint8Array): string => (
        Array.from(new Uint8Array(buffer))
            .map(x => x.toString(16).padStart(2, '0'))
            .join('')
    )

    convertHexToBuffer = (hex: string): Uint8Array => (
        new Uint8Array(Buffer.from(hex, 'hex'))
    )

    getPasswordDerivedAssets = (password: string, salt: string) => {
        if (!password || !salt) {
            throw new Error('Missing arguments, need both password and salt');
        }

        const userKey = this.deriveKey(password, salt);
        const userPassword = this.deriveKey(userKey, salt);

        return {
            userKey,
            userPassword,
            salt
        };
    }

    deriveKey = (string: string, salt: string) => {
        const iterations = 1000;
        const key = CryptoJS.PBKDF2(string, salt, { keySize: 256 / 32, iterations, hasher: CryptoJS.algo.SHA256 });
        return key.toString(CryptoJS.enc.Hex);
    }

    decryptStringToArrayBuffer = (keyType: string | null, key: string, string: string) => {
        switch (keyType) {
            case KEY_SERVER: {
                return this.decryptStringToArrayBufferWithServerKey(key, string);
            }

            case KEY_USER: {
                return this.decryptStringToArrayBufferWithUserKey(key, string);
            }
        }
    }

    decryptStringToArrayBufferWithServerKey = (serverKey: string, string: string) => {
        return this.decryptStringWithServerKey(serverKey, string, (wordArray: CryptoJS.lib.WordArray) => {
            return this.wordArrayToArrayBuffer(wordArray);
        });
    }

    decryptStringToArrayBufferWithUserKey = (userKey: string, string: string) => {
        if (!userKey.length) {
            throw new Error('Can\'t decryptWithUserKey: No user key set');
        }

        let wa = this.decryptWithAES(string, userKey);
        let ab = this.wordArrayToArrayBuffer(wa);
        return ab;
    }

    decryptStringWithServerKey = (serverKey: string, string: string, returnTransform: Function | null = null) => {
        if (!serverKey.length) {
            throw new Error('Can\'t decryptWithServerKey: No server key set');
        }

        var parts = string.split(':');
        if (parts[0] !== 'sig') {
            throw new Error('Invalid format for this._serverKey-signed string');
        }

        let words = CryptoJS.enc.Base64.parse(parts[2]);

        return returnTransform ? returnTransform(words) : CryptoJS.enc.Utf8.stringify(words);
    }

    decryptWithAES = (input: string, key: string) => {
        const decrypted = CryptoJS.AES.decrypt(input, key);
        return decrypted;
    }

    wordArrayToArrayBuffer = (wordArray: CryptoJS.lib.WordArray) => {
        let bufView = new Uint8Array(wordArray.sigBytes);
        let word;
        let i;
        let j;
        let pos = 0;

        for (i = 0; i < wordArray.words.length; ++i) {
            word = wordArray.words[i];
            for (j = 3; j >= 0 && pos < wordArray.sigBytes; --j) {
                bufView[pos++] = (word >> 8 * j) & 0xFF;
            }
        }

        return bufView.buffer;
    }
}