import { AppSubstrate } from '.';
import { AppSubstrateAuthHeader, AppSubstrateRequest, PAYLOADTYPE_JSON, PAYLOADTYPE_PROTOBUF, RESPONSE_ARRAYBUFFER, RESPONSE_CONTAINER, RESPONSE_PROTOBUF, StringIndexedObject } from './types';
import { Any } from 'google-protobuf/google/protobuf/any_pb'; // TODO: use protobuf dir any?

export class RequestBuilder {

    private static _url: string = '';
    private static _clientAccount: string = '';
    private static _clientBrand: string = '';
    private static _clientSubBrand: string = '';
    private static _version: string = '';

    static set url(url: string) {
        RequestBuilder._url = url;
    }

    static set clientAccount(clientAccount: string) {
        RequestBuilder._clientAccount = clientAccount;
    }

    static set clientBrand(clientBrand: string) {
        RequestBuilder._clientBrand = clientBrand;
    }

    static set clientSubBrand(clientSubBrand: string) {
        RequestBuilder._clientSubBrand = clientSubBrand;
    }

    static set version(version: string) {
        RequestBuilder._version = version;
    }

    category: string = '';

    userKey: string = '';
    passwordKey: string = '';

    payload: StringIndexedObject | string = {};
    headers: StringIndexedObject = {};
    payloadType: typeof PAYLOADTYPE_JSON | typeof PAYLOADTYPE_PROTOBUF = PAYLOADTYPE_JSON;
    responseType: typeof RESPONSE_CONTAINER | typeof RESPONSE_ARRAYBUFFER | typeof RESPONSE_PROTOBUF = RESPONSE_CONTAINER;
    httpMethod: 'POST' | 'GET' = 'POST';

    build = (): Request => {
        const container = this._getRequestContainer();

        const rawRequest = {
            mode: 'cors' as RequestMode,
            redirect: 'follow' as RequestRedirect,
            cache: 'default' as RequestCache,
            headers: {
                'Cache-Control': 'no-cache',
                'X-AppSubstrate-ClientVersion': RequestBuilder.version,
            },
        };

        const request: AppSubstrateRequest = { ...rawRequest, method: this.httpMethod, body: JSON.stringify(container.finish()) }
        return new Request(RequestBuilder._url, request);
    }

    private _getRequestContainer = (): RequestContainer => {
        const container = new RequestContainer();

        container.clientAccount = RequestBuilder._clientAccount;
        container.clientBrand = RequestBuilder._clientBrand;
        container.clientSubBrand = RequestBuilder._clientSubBrand;
        container.clientVersion = RequestBuilder._version;
        container.category = this.category;
        container.authentication = this._getAuthenticationHeader();
        container.payload = this.payload;
        container.headers = this.headers;
        container.payloadType = this.payloadType;

        return container;
    }

    private _getAuthenticationHeader = (): AppSubstrateAuthHeader | null => {
        return this.userKey.length && this.passwordKey.length ? { user: this.userKey, pass: this.passwordKey } : null;
    }

    packageProtobufPayload = (query: Uint8Array, identifier: string): string => {
        const any = new Any();
        any.pack(query, identifier);
        return AppSubstrate.crypto.convertBufferToHex(any.serializeBinary());
    }
}

// RequestContainer

class RequestContainer {

    containerFormatVersion: number = 2;
    clientAccount: string = '';
    clientBrand: string = '';
    clientSubBrand: string = '';
    clientVersion: string = '';

    category: string = '';

    authentication: AppSubstrateAuthHeader | null = null;

    payload: StringIndexedObject | string = {};
    headers: StringIndexedObject = {};
    payloadType: typeof PAYLOADTYPE_JSON | typeof PAYLOADTYPE_PROTOBUF = PAYLOADTYPE_JSON;

    finish = () => {
        return {
            containerFormatVersion: this.containerFormatVersion,

            clientAccount: this.clientAccount,
            clientBrand: this.clientBrand,
            clientSubBrand: this.clientSubBrand,
            clientVersion: this.clientVersion,

            category: this.category,

            authentication: this.authentication,
            payload: this.payload,
            payloadType: this.payloadType,
            header: { ...this.headers, language: 'nl', locale: 'nl-NL' }, // TODO: localize

            attachmentsEncrypted: null,
            attachmentsEncryptionKeyType: null,
            payloadEncrypted: false,
            payloadEncryptionKeyType: null,

            heap: {},

            clientRequestTime: +new Date()
        }
    }
}

// Request

class Request {

    private _url: string;
    private _request: AppSubstrateRequest;

    constructor(url: string, request: AppSubstrateRequest) {
        this._url = url;
        this._request = request;
    }

    fetch = async () => {
        const url = `${this._url}/appsubstrate/`;
        return await fetch(url, this._request);
    }
}
