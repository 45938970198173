import { singleton } from 'localization';

const escapeString = (str: string): string => {
    return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

const interpolate = (string: string, replacements: { [property: string]: string }): string => {
    Object.keys(replacements).forEach((key) => {
        string = string.replace(`{{${key}}}`, escapeString(replacements[key]));
    });
    return string;
}

export const T = (string: string, interpolation: { [property: string]: string } = {}): string => {
    const { language, translations } = singleton;

    const translated: string = translations[language] && Object.keys(translations[language]).length
        ? string.split('.').reduce((a: any, b: string) => a[b] ?? string, translations[language]) || string
        : string;
        
    return interpolate(translated, interpolation);
}
