import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { T } from 'localization';

import IntroPageViewModel from '../viewModel';

import './IntroPage.scss';
// import { useHistory } from 'react-router-dom';

const IntroPageView: FC = observer(() => {
    const viewModel = IntroPageViewModel();
    const assets = viewModel.getAssets();

    // const history = useHistory();
    // const state = history.location.state as { data: string, type: string };

    return (
        <div>
            <div className='column'>
                <img className='intro-logo' src={assets.logo} alt='' />

                <img className='intro-login' src={assets.login} alt='' />

                <div className='intro-text'>
                    <h4>{T('intro.title')}</h4>
                    <p dangerouslySetInnerHTML={{ __html: T('intro.description') }} style={{ whiteSpace: 'pre-wrap' }} />
                </div>

            </div>
        </div>
    );
});

export default IntroPageView;
