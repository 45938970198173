import { createContext, FC, useContext, useEffect } from 'react';
import { makeAutoObservable } from 'mobx';

class LocalizationStore {
    language: string = navigator.language.trim().split(/-|_/)[0];
    translations: { [string: string]: string } = {};

    constructor() {
        makeAutoObservable(this)
    }

    setLanguage(language: string) {
        this.language = language;
    }

    setTranslations(translations: { [string: string]: string }) {
        this.translations = translations;
    }
}

const singleton = new LocalizationStore();

const LocalizationContext = createContext<LocalizationStore>(new LocalizationStore());

const LocalizationProvider: FC<{ fetchTranslations: Function }> = ({ fetchTranslations, children }) => {
    
    useEffect(() => {
        singleton.setTranslations(fetchTranslations({ language: singleton.language }));
    }, [fetchTranslations]);

    return (
        <LocalizationContext.Provider value={singleton}>
            {children}
        </LocalizationContext.Provider>
    );
}

const useLocalization = () => {
    const context = useContext(LocalizationContext);
    if (context === undefined) {
        throw new Error('useLocalization must be used within a LocalizationProvider');
    }
    return context;
}


export { LocalizationStore, LocalizationProvider, useLocalization, singleton };