import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { NavBar, DropdownMenu, DropdownItem } from 'framework';

import icons from 'assets/svg/bf-icons.svg';

import NavigationViewModel from '../viewModel';
import { useLocalization } from 'localization';

const NavBarView: FC<{ viewModel: ReturnType<typeof NavigationViewModel> }> = observer(({ viewModel }) => {
    const localization = useLocalization();
    const languageCodes = viewModel.getLanguageCodes();

    return (
        <div>
            <NavBar logo={viewModel.getHeader()}>
                {languageCodes.length && languageCodes.length > 1 ?
                    <DropdownMenu label={
                        <div className='row'>
                            {localization.language.toUpperCase()}

                            <div className='icon-container'>
                                <svg className='icon'>
                                    <use xlinkHref={`${icons}#chevron-down-small`} />
                                </svg>
                            </div>
                        </div>
                    }>
                        {viewModel.config.Bedside?.enabledLanguageCodes.map((language, i) =>
                            <DropdownItem
                                key={i}
                                label={language.toUpperCase()}
                                onClick={() => localization.setLanguage(language)}
                            />
                        )}
                    </DropdownMenu>
                    : null}
            </NavBar>
        </div>
    );
})

export default NavBarView;
