import { FC } from 'react';

import { APPLICATION_PDF, DocumentViewModel, VIDEO_MP4 } from '../viewModel';
import PDFView from './PDFView';

import './Document.scss';
import { observer } from 'mobx-react-lite';
import { Spinner } from 'framework';

const DocumentView: FC<{ link: IDiscoveryLink }> = ({ link }) => {
    const viewModel = new DocumentViewModel(link);
    return <Document viewModel={viewModel} />
}

const Document: FC<{ viewModel: DocumentViewModel }> = observer(({ viewModel }) => {
    const contentType = viewModel.getContentType();

    return (
        <div className='document'>
            {contentType === APPLICATION_PDF
                ?
                <PDFView viewModel={viewModel} />
                :
                contentType === VIDEO_MP4
                    ?
                    viewModel.data ? <video width='100%' height='100%' src={viewModel.data} controls autoPlay>
                        <source type='video/mp4' />
                    </video>
                        : <div className='center'><Spinner /></div>
                    :
                    <div>{`Unsupported content-type: ${contentType}`}</div>}
        </div>
    );
});

export default DocumentView;
