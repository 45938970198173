import Countly from 'countly-sdk-web';

export class CountlySingleton {

    entertainment = 'Entertainment';
    information = 'Information';

    countryCode = undefined;

    static shared = new CountlySingleton();

    initialize = (appKey) => {
        Countly.init({
            app_key: appKey,
            url: 'https://analytics.brightfish.nl',
            session_update: 10,
            use_session_cookie: true,
            debug: false,
            require_consent: true,
            namespace: 'bedside-web',
            inactivity_time: 1,
            offline_mode: false,
        });

        Countly.q.push(['group_features', {
            activity: ['sessions', 'events', 'views', 'location'],
            interaction: ['scrolls', 'clicks', 'crashes'],
            whereabouts: ['users']
        }]);

        const consents = ['activity', 'interaction', 'whereabouts'];
        Countly.q.push(['add_consent', consents]);

        Countly.q.push(['track_sessions']);
        Countly.q.push(['track_scrolls']);
        Countly.q.push(['track_clicks']);
        Countly.q.push(['track_links']);
        Countly.q.push(['track_errors']);
    }

    registerPageView = (pathname) => {
        Countly.q.push(['track_pageview', pathname]);
        Countly.q.push(['initialize_feedback_popups']);
    }

    registerEvent = async (key, identifier) => {
        if (!this.countryCode) {
            const response = await fetch('https://ip.nf/me.json', { method: 'get' });
            const data = await response.json();
            this.countryCode = data.ip.country_code;
        }
        Countly.q.push(['add_event', {
            key: key,
            segmentation: {
                'country': this.countryCode,
                'app_version': process.env.REACT_APP_VERSION,
                'app': identifier,
                [identifier]: identifier
            }
        }]);
    }

    assessKey = (containerName) => {
        switch (containerName.toLowerCase()) {
            case 'information':
                return this.information;
            case 'entertainment':
                return this.entertainment;
            default:
                return undefined;
        }
    }
}