import DiscoveryItem from './DiscoveryItem';
import DiscoveryTranslator from './DiscoveryTranslator';

class DiscoveryCategory {
    id: number;
    title: string;
    translations: IDiscoveryTranslation[];
    items: DiscoveryItem[];

    constructor(category: IDiscoveryCategory) {
        this.id = category.id;
        this.title = category.internal_title;
        this.translations = category.translations;
        this.items = [];
    }

    static async build(category: IDiscoveryCategory) {
        const self = new DiscoveryCategory(category);
        const items = category.items.map(async (item) => await DiscoveryItem.build(item));
        self.items = await Promise.all(items);
        return self;
    }

    getTitle() {
        return DiscoveryTranslator(this.translations, 'title') ?? this.title;
    }

    getDescription() {
        return DiscoveryTranslator(this.translations, 'description');
    }

}

export default DiscoveryCategory;