import { useCallback, useEffect, useState } from 'react';
import { AppSubstrate } from 'appsubstrate';

import { iAssets } from 'context';
import { iConfig, iTranslations } from 'branding/types';
import { CountlySingleton } from 'services';

export interface iAppModel {
    appInitialized: React.SetStateAction<boolean>
    getAssets: () => iAssets
    getConfig: () => iConfig
    getTranslations: () => iTranslations
}

type Branding = {
    favicon: string
    header: string
    logo: string
    login: string
    config: iConfig
    translations: iTranslations
}

export const AppModel = (): iAppModel => {
    const [appInitialized, setAppInitialized] = useState(false);
    const [branding, setBranding] = useState<Branding>({
        favicon: '',
        header: '',
        logo: '',
        login: '',
        config: {} as iConfig,
        translations: {} as iTranslations
    });

    const getClientAccount = (): string => {
        return process.env.REACT_APP_CLIENT_ACCOUNT!;
    }

    const getClientVersion = (): string => {
        return process.env.REACT_APP_VERSION!;
    }

    const initializeApp = useCallback(
        async () => {
            if (appInitialized) return;

            const module = await import(`branding/${getClientAccount()}`);

            const favicon = (document.getElementById('favicon') as HTMLLinkElement);
            favicon.href = module.favicon;
            document.title = module.config.title;

            const config = module.config;

            setBranding({
                favicon: module.favicon,
                header: module.header,
                logo: module.logo,
                login: module.login,
                config: config,
                translations: module.translations
            });

            if (!AppSubstrate.initialized && config.hasOwnProperty('Bedside')) {
                await AppSubstrate.initialize({
                    url: config.Bedside?.api.url ?? '',
                    clientAccount: config.Bedside?.api.meta.clientAccount ?? '',
                    clientBrand: config.Bedside?.api.meta.clientBrand ?? '',
                    clientSubBrand: '',
                    version: getClientVersion()
                });
            }

            CountlySingleton.shared.initialize(config.Bedside?.countlyKey);

            setAppInitialized(true);
        },
        [appInitialized]
    );

    useEffect(() => {
        initializeApp();
    }, [initializeApp]);

    const getAssets = (): iAssets => {
        return {
            favicon: branding.favicon,
            header: branding.header,
            logo: branding.logo,
            login: branding.login
        }
    }

    const getConfig = (): iConfig => {
        return branding.config;
    }

    const getTranslations = (): iTranslations => {
        return branding.translations;
    }

    return {
        appInitialized,
        getAssets,
        getConfig,
        getTranslations
    }
}