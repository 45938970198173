import { FC } from 'react';
import { Discovery } from 'components/Discovery';

const EntertainmentView: FC = () => {

    return (
        <Discovery containerName={'Entertainment'} />
    );
}

export default EntertainmentView;
