import { AppSubstrate, RequestBuilder, RESPONSE_ARRAYBUFFER } from 'appsubstrate';
import { BedsideService } from 'services';

class DiscoveryImage {
    filename: string;
    url: string;
    image: string;

    service: BedsideService = new BedsideService();

    constructor(image: IDiscoveryImage) {
        this.filename = image.filename;
        this.url = image.data.url;
        this.image = '';
    }

    static async build (image: IDiscoveryImage) {
        const self = new DiscoveryImage(image);

        if (!self.url) {
            return;
        }

        const path = `content-assets/${self.url}`;
        const responseArrayBuffer = await self.service.getFile(path);

        const base64 = btoa(
            new Uint8Array(responseArrayBuffer).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
            ),
        );

        self.image = `data:;base64,${base64}`;

        return self;
    }

    getImage() {
        return this.image;
    }

    setImage(image: string) {
        this.image = image;
    }

    async requestImage() {

        if (!this.url) {
            return;
        }

        const path = `content-assets/${this.url}`;

        const request = new AppSubstrate();
        
        const builder: RequestBuilder = request.builder;
        builder.category = 'GetGenericFile';
        builder.headers = { path };
        builder.responseType = RESPONSE_ARRAYBUFFER;

        const response = await request.run(builder);

        const base64 = btoa(
            new Uint8Array(response).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
            ),
        );
        this.setImage(`data:;base64,${base64}`);
    }

}

export default DiscoveryImage;