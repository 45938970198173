import { FC } from 'react';
import { TabBar, TabItem, Ripple } from 'framework';

import icons from 'assets/svg/bf-icons.svg';

import NavigationViewModel from '../viewModel';
import { observer } from 'mobx-react-lite';

const TabBarView: FC<{ viewModel: ReturnType<typeof NavigationViewModel> }> = observer(({ viewModel }) => {
    return (
        <TabBar>

            {viewModel.getPathMap().map((path) =>
                <TabItem
                    key={path}
                    onClick={() => viewModel.handleTabClick(path)}
                    path={path}
                    currentPage={viewModel.location.pathname}
                >
                    <svg className='tab-icon'>
                        <use xlinkHref={`${icons}#${viewModel.getIconForPath(path)}`} />
                    </svg>

                    {viewModel.getTitleForPath(path)}

                    <Ripple />
                </TabItem>
            )}

        </TabBar>
    );
});

export default TabBarView;
