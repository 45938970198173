import React, { useState, useLayoutEffect } from 'react';

import './Ripple.scss';

const useDebouncedRippleCleanUp = (rippleCount: number, duration: number, cleanUpFunction: () => void) => {
    useLayoutEffect(() => {
        let bounce: any = null;
        if (rippleCount > 0) {
            clearTimeout(bounce);

            bounce = setTimeout(() => {
                cleanUpFunction();
                clearTimeout(bounce);
            }, duration * 4);
        }

        return () => clearTimeout(bounce);
    }, [rippleCount, duration, cleanUpFunction]);
};

type Props = {
    duration?: number;
    color?: string;
}

const Ripple = ({ duration = 500, color = '#C7C7C7' }: Props) => {

    const [rippleArray, setRippleArray] = useState<{ x: number, y: number, size: number }[]>([]);

    useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
        setRippleArray([]);
    });

    const addRipple = (e: { currentTarget: { getBoundingClientRect: () => any; }; pageX: number; pageY: number; }) => {

        const rippleContainer = e.currentTarget.getBoundingClientRect();
        const size: number = rippleContainer.width > rippleContainer.height ? rippleContainer.width : rippleContainer.height;

        const x: number = e.pageX - rippleContainer.x - rippleContainer.width / 2;
        const y: number = e.pageY - (rippleContainer.y + window.scrollY) - rippleContainer.width / 2;
        const ripple = { x, y, size };

        setRippleArray((prevState) => [...prevState, ripple]);

    }

    return (

        <div className='ripple-container' onClick={addRipple}>
            {
                rippleArray.length > 0 &&
                rippleArray.map((ripple, index) => {
                    return (
                        <span
                            className='ripple'
                            key={"ripple_" + index}
                            style={{
                                top: ripple.y,
                                left: ripple.x,
                                width: ripple.size,
                                height: ripple.size,
                                backgroundColor: color,
                                animationDuration: duration + 'ms'
                            }}
                        />
                    );
                })}
        </div>

    );

}

export default Ripple;