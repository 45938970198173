import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Card, Spinner, SearchInput, Modal } from 'framework';
import { T } from 'localization';
import { DiscoveryCategory, DiscoveryItem } from 'store';

import { Document } from 'components/Document';

import DiscoveryViewModel from '../viewModel';

import icons from 'assets/svg/bf-icons.svg';
import './Discovery.scss';

const DiscoveryView: FC<{ containerName: string }> = observer(({ containerName }) => {
    const viewModel = DiscoveryViewModel(containerName);

    return (
        <div className='responsive-container'>

            {!viewModel.getContainerStructure() ?

                <div className='discovery-spinner'>
                    <Spinner />
                </div>

                : <div>
                    <h4 className='headline'>{T(`discovery.${containerName.toLowerCase()}.title`)}</h4>

                    <div className='discovery-search-input'>
                        <SearchInput
                            placeholder={`${T('discovery.search')}...`}
                            value={viewModel.searchQuery}
                            handleSearch={viewModel.handleSearch}
                        />
                    </div>

                    {viewModel.getContainerStructure()!.map((struct: DiscoveryCategory) => (
                        <Card key={struct.id} padding={'none'}>
                            <Collapse
                                key={viewModel.searchQuery}
                                collapsed={!viewModel.searchQuery.length}
                                showLabel={
                                    <div className='discovery-category separator'>
                                        <div>{struct.getTitle()}</div>
                                        <svg className='icon discovery-chevron'><use xlinkHref={`${icons}#chevron-down-small`} /></svg>
                                    </div>
                                }
                                hideLabel={
                                    <div className='discovery-category show separator'>
                                        <div>{struct.getTitle()}</div>
                                        <svg className='icon discovery-chevron'><use xlinkHref={`${icons}#chevron-up-small`} /></svg>
                                    </div>
                                }
                            >

                                {struct.items.map((item: DiscoveryItem) => (
                                    <div key={item.id} className='discovery-item separator link' onClick={() => { viewModel.redirect(item.link); viewModel.registerEvent(item); }}>
                                        <div className='row'>
                                            <div className='row-item'>
                                                {item.image?.getImage() ? <div className='icon-container'><img className='icon' src={item.image?.getImage()} alt=''></img></div> : null}
                                            </div>
                                            <div className='row-item'>
                                                {item.getTitle()}
                                            </div>
                                        </div>
                                        <svg className='icon discovery-chevron'><use xlinkHref={`${icons}#chevron-right-small`} /></svg>
                                    </div>
                                ))}

                            </Collapse>
                        </Card>
                    ))}
                </div>}

            {viewModel.activeLink
                ?
                <Modal key={viewModel.activeLink.document.checksum} show={!!viewModel.activeLink} handleClose={() => viewModel.setActiveLink(undefined)}>
                    <Document link={viewModel.activeLink} />
                </Modal>
                : 
                null}
        </div>
    );
});

export default DiscoveryView;
