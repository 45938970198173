import { AppSubstrate, RequestBuilder, RESPONSE_ARRAYBUFFER } from 'appsubstrate';

export class BedsideService {

    private appsubstrate: AppSubstrate = new AppSubstrate();

    getContainer = (containerName: string) => {
        const builder: RequestBuilder = this.appsubstrate.builder;
        builder.category = 'GetGenericContainer';
        builder.payload = { containerName };
        return this.appsubstrate.run(builder);
    }

    getFile = (path: string) => {
        const builder: RequestBuilder = this.appsubstrate.builder;
        builder.category = 'GetGenericFile';
        builder.headers = { path };
        builder.responseType = RESPONSE_ARRAYBUFFER;
        return this.appsubstrate.run(builder);
    }
}