/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";
import { Any } from "./google/protobuf/any";
import { Diagnostics } from "./Brightfish.Transport.Performance";

export const protobufPackage = "Brightfish.EMR.Transport";

export enum ClusterStatusCode {
  ClusterStatusCodeUnknown = 0,
  ClusterStatusCodeStarting = 1,
  ClusterStatusCodeDisconnected = 2,
  ClusterStatusCodePreparing = 3,
  ClusterStatusCodeActive = 4,
  ClusterStatusCodeExiting = 5,
  UNRECOGNIZED = -1,
}

export function clusterStatusCodeFromJSON(object: any): ClusterStatusCode {
  switch (object) {
    case 0:
    case "ClusterStatusCodeUnknown":
      return ClusterStatusCode.ClusterStatusCodeUnknown;
    case 1:
    case "ClusterStatusCodeStarting":
      return ClusterStatusCode.ClusterStatusCodeStarting;
    case 2:
    case "ClusterStatusCodeDisconnected":
      return ClusterStatusCode.ClusterStatusCodeDisconnected;
    case 3:
    case "ClusterStatusCodePreparing":
      return ClusterStatusCode.ClusterStatusCodePreparing;
    case 4:
    case "ClusterStatusCodeActive":
      return ClusterStatusCode.ClusterStatusCodeActive;
    case 5:
    case "ClusterStatusCodeExiting":
      return ClusterStatusCode.ClusterStatusCodeExiting;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClusterStatusCode.UNRECOGNIZED;
  }
}

export function clusterStatusCodeToJSON(object: ClusterStatusCode): string {
  switch (object) {
    case ClusterStatusCode.ClusterStatusCodeUnknown:
      return "ClusterStatusCodeUnknown";
    case ClusterStatusCode.ClusterStatusCodeStarting:
      return "ClusterStatusCodeStarting";
    case ClusterStatusCode.ClusterStatusCodeDisconnected:
      return "ClusterStatusCodeDisconnected";
    case ClusterStatusCode.ClusterStatusCodePreparing:
      return "ClusterStatusCodePreparing";
    case ClusterStatusCode.ClusterStatusCodeActive:
      return "ClusterStatusCodeActive";
    case ClusterStatusCode.ClusterStatusCodeExiting:
      return "ClusterStatusCodeExiting";
    default:
      return "UNKNOWN";
  }
}

export enum ErrorCode {
  ErrorCodeUnknown = 0,
  ErrorCodeParsing = 1001,
  ErrorCodeInvalidRequestId = 1002,
  ErrorCodeInvalidSession = 1003,
  ErrorCodeAuthLevel = 1004,
  ErrorCodeTimeout = 1005,
  ErrorCodeSessionFrozen = 1006,
  ErrorCodeUnsupportedRequestObject = 1007,
  ErrorCodeAlreadySubscribed = 1008,
  ErrorCodeNotSubscribed = 1009,
  ErrorCodeInvalidQuery = 1010,
  ErrorCodeRequestNotAQuery = 1011,
  ErrorCodeBadRequest = 1012,
  ErrorCodeRequestCanceled = 1013,
  ErrorCodeInternal = 1500,
  ErrorCodeNoWorkers = 1501,
  ErrorCodeWorkerRefusedConnection = 1502,
  ErrorCodeWorkerDisconnected = 1503,
  ErrorCodeWorkerInvalidResponse = 1504,
  ErrorCodeRequestIdMismatch = 1505,
  ErrorCodeRequestLimitReached = 1506,
  ErrorCodeNotImplemented = 1507,
  UNRECOGNIZED = -1,
}

export function errorCodeFromJSON(object: any): ErrorCode {
  switch (object) {
    case 0:
    case "ErrorCodeUnknown":
      return ErrorCode.ErrorCodeUnknown;
    case 1001:
    case "ErrorCodeParsing":
      return ErrorCode.ErrorCodeParsing;
    case 1002:
    case "ErrorCodeInvalidRequestId":
      return ErrorCode.ErrorCodeInvalidRequestId;
    case 1003:
    case "ErrorCodeInvalidSession":
      return ErrorCode.ErrorCodeInvalidSession;
    case 1004:
    case "ErrorCodeAuthLevel":
      return ErrorCode.ErrorCodeAuthLevel;
    case 1005:
    case "ErrorCodeTimeout":
      return ErrorCode.ErrorCodeTimeout;
    case 1006:
    case "ErrorCodeSessionFrozen":
      return ErrorCode.ErrorCodeSessionFrozen;
    case 1007:
    case "ErrorCodeUnsupportedRequestObject":
      return ErrorCode.ErrorCodeUnsupportedRequestObject;
    case 1008:
    case "ErrorCodeAlreadySubscribed":
      return ErrorCode.ErrorCodeAlreadySubscribed;
    case 1009:
    case "ErrorCodeNotSubscribed":
      return ErrorCode.ErrorCodeNotSubscribed;
    case 1010:
    case "ErrorCodeInvalidQuery":
      return ErrorCode.ErrorCodeInvalidQuery;
    case 1011:
    case "ErrorCodeRequestNotAQuery":
      return ErrorCode.ErrorCodeRequestNotAQuery;
    case 1012:
    case "ErrorCodeBadRequest":
      return ErrorCode.ErrorCodeBadRequest;
    case 1013:
    case "ErrorCodeRequestCanceled":
      return ErrorCode.ErrorCodeRequestCanceled;
    case 1500:
    case "ErrorCodeInternal":
      return ErrorCode.ErrorCodeInternal;
    case 1501:
    case "ErrorCodeNoWorkers":
      return ErrorCode.ErrorCodeNoWorkers;
    case 1502:
    case "ErrorCodeWorkerRefusedConnection":
      return ErrorCode.ErrorCodeWorkerRefusedConnection;
    case 1503:
    case "ErrorCodeWorkerDisconnected":
      return ErrorCode.ErrorCodeWorkerDisconnected;
    case 1504:
    case "ErrorCodeWorkerInvalidResponse":
      return ErrorCode.ErrorCodeWorkerInvalidResponse;
    case 1505:
    case "ErrorCodeRequestIdMismatch":
      return ErrorCode.ErrorCodeRequestIdMismatch;
    case 1506:
    case "ErrorCodeRequestLimitReached":
      return ErrorCode.ErrorCodeRequestLimitReached;
    case 1507:
    case "ErrorCodeNotImplemented":
      return ErrorCode.ErrorCodeNotImplemented;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ErrorCode.UNRECOGNIZED;
  }
}

export function errorCodeToJSON(object: ErrorCode): string {
  switch (object) {
    case ErrorCode.ErrorCodeUnknown:
      return "ErrorCodeUnknown";
    case ErrorCode.ErrorCodeParsing:
      return "ErrorCodeParsing";
    case ErrorCode.ErrorCodeInvalidRequestId:
      return "ErrorCodeInvalidRequestId";
    case ErrorCode.ErrorCodeInvalidSession:
      return "ErrorCodeInvalidSession";
    case ErrorCode.ErrorCodeAuthLevel:
      return "ErrorCodeAuthLevel";
    case ErrorCode.ErrorCodeTimeout:
      return "ErrorCodeTimeout";
    case ErrorCode.ErrorCodeSessionFrozen:
      return "ErrorCodeSessionFrozen";
    case ErrorCode.ErrorCodeUnsupportedRequestObject:
      return "ErrorCodeUnsupportedRequestObject";
    case ErrorCode.ErrorCodeAlreadySubscribed:
      return "ErrorCodeAlreadySubscribed";
    case ErrorCode.ErrorCodeNotSubscribed:
      return "ErrorCodeNotSubscribed";
    case ErrorCode.ErrorCodeInvalidQuery:
      return "ErrorCodeInvalidQuery";
    case ErrorCode.ErrorCodeRequestNotAQuery:
      return "ErrorCodeRequestNotAQuery";
    case ErrorCode.ErrorCodeBadRequest:
      return "ErrorCodeBadRequest";
    case ErrorCode.ErrorCodeRequestCanceled:
      return "ErrorCodeRequestCanceled";
    case ErrorCode.ErrorCodeInternal:
      return "ErrorCodeInternal";
    case ErrorCode.ErrorCodeNoWorkers:
      return "ErrorCodeNoWorkers";
    case ErrorCode.ErrorCodeWorkerRefusedConnection:
      return "ErrorCodeWorkerRefusedConnection";
    case ErrorCode.ErrorCodeWorkerDisconnected:
      return "ErrorCodeWorkerDisconnected";
    case ErrorCode.ErrorCodeWorkerInvalidResponse:
      return "ErrorCodeWorkerInvalidResponse";
    case ErrorCode.ErrorCodeRequestIdMismatch:
      return "ErrorCodeRequestIdMismatch";
    case ErrorCode.ErrorCodeRequestLimitReached:
      return "ErrorCodeRequestLimitReached";
    case ErrorCode.ErrorCodeNotImplemented:
      return "ErrorCodeNotImplemented";
    default:
      return "UNKNOWN";
  }
}

export enum MetricsValueType {
  MetricsValueTypeUnknown = 0,
  MetricsValueTypeCounter = 1,
  MetricsValueTypeMeasurement = 2,
  MetricsValueTypePercentage = 3,
  UNRECOGNIZED = -1,
}

export function metricsValueTypeFromJSON(object: any): MetricsValueType {
  switch (object) {
    case 0:
    case "MetricsValueTypeUnknown":
      return MetricsValueType.MetricsValueTypeUnknown;
    case 1:
    case "MetricsValueTypeCounter":
      return MetricsValueType.MetricsValueTypeCounter;
    case 2:
    case "MetricsValueTypeMeasurement":
      return MetricsValueType.MetricsValueTypeMeasurement;
    case 3:
    case "MetricsValueTypePercentage":
      return MetricsValueType.MetricsValueTypePercentage;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MetricsValueType.UNRECOGNIZED;
  }
}

export function metricsValueTypeToJSON(object: MetricsValueType): string {
  switch (object) {
    case MetricsValueType.MetricsValueTypeUnknown:
      return "MetricsValueTypeUnknown";
    case MetricsValueType.MetricsValueTypeCounter:
      return "MetricsValueTypeCounter";
    case MetricsValueType.MetricsValueTypeMeasurement:
      return "MetricsValueTypeMeasurement";
    case MetricsValueType.MetricsValueTypePercentage:
      return "MetricsValueTypePercentage";
    default:
      return "UNKNOWN";
  }
}

export enum RestartSubProcessesError {
  RestartSubProcessesErrorUnknown = 0,
  RestartSubProcessesErrorUnknownSubProcess = 1,
  RestartSubProcessesErrorUnknownNode = 2,
  RestartSubProcessesErrorNotRunningOnNode = 3,
  UNRECOGNIZED = -1,
}

export function restartSubProcessesErrorFromJSON(
  object: any
): RestartSubProcessesError {
  switch (object) {
    case 0:
    case "RestartSubProcessesErrorUnknown":
      return RestartSubProcessesError.RestartSubProcessesErrorUnknown;
    case 1:
    case "RestartSubProcessesErrorUnknownSubProcess":
      return RestartSubProcessesError.RestartSubProcessesErrorUnknownSubProcess;
    case 2:
    case "RestartSubProcessesErrorUnknownNode":
      return RestartSubProcessesError.RestartSubProcessesErrorUnknownNode;
    case 3:
    case "RestartSubProcessesErrorNotRunningOnNode":
      return RestartSubProcessesError.RestartSubProcessesErrorNotRunningOnNode;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RestartSubProcessesError.UNRECOGNIZED;
  }
}

export function restartSubProcessesErrorToJSON(
  object: RestartSubProcessesError
): string {
  switch (object) {
    case RestartSubProcessesError.RestartSubProcessesErrorUnknown:
      return "RestartSubProcessesErrorUnknown";
    case RestartSubProcessesError.RestartSubProcessesErrorUnknownSubProcess:
      return "RestartSubProcessesErrorUnknownSubProcess";
    case RestartSubProcessesError.RestartSubProcessesErrorUnknownNode:
      return "RestartSubProcessesErrorUnknownNode";
    case RestartSubProcessesError.RestartSubProcessesErrorNotRunningOnNode:
      return "RestartSubProcessesErrorNotRunningOnNode";
    default:
      return "UNKNOWN";
  }
}

export enum PushType {
  PushTypeUnknown = 0,
  PushTypeTask = 1,
  PushTypeDetailView = 2,
  PushTypeClipboard = 3,
  UNRECOGNIZED = -1,
}

export function pushTypeFromJSON(object: any): PushType {
  switch (object) {
    case 0:
    case "PushTypeUnknown":
      return PushType.PushTypeUnknown;
    case 1:
    case "PushTypeTask":
      return PushType.PushTypeTask;
    case 2:
    case "PushTypeDetailView":
      return PushType.PushTypeDetailView;
    case 3:
    case "PushTypeClipboard":
      return PushType.PushTypeClipboard;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PushType.UNRECOGNIZED;
  }
}

export function pushTypeToJSON(object: PushType): string {
  switch (object) {
    case PushType.PushTypeUnknown:
      return "PushTypeUnknown";
    case PushType.PushTypeTask:
      return "PushTypeTask";
    case PushType.PushTypeDetailView:
      return "PushTypeDetailView";
    case PushType.PushTypeClipboard:
      return "PushTypeClipboard";
    default:
      return "UNKNOWN";
  }
}

export enum ReleaseType {
  ReleaseTypeManual = 0,
  ReleaseTypeInstant = 1,
  ReleaseTypeAuto = 2,
  UNRECOGNIZED = -1,
}

export function releaseTypeFromJSON(object: any): ReleaseType {
  switch (object) {
    case 0:
    case "ReleaseTypeManual":
      return ReleaseType.ReleaseTypeManual;
    case 1:
    case "ReleaseTypeInstant":
      return ReleaseType.ReleaseTypeInstant;
    case 2:
    case "ReleaseTypeAuto":
      return ReleaseType.ReleaseTypeAuto;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReleaseType.UNRECOGNIZED;
  }
}

export function releaseTypeToJSON(object: ReleaseType): string {
  switch (object) {
    case ReleaseType.ReleaseTypeManual:
      return "ReleaseTypeManual";
    case ReleaseType.ReleaseTypeInstant:
      return "ReleaseTypeInstant";
    case ReleaseType.ReleaseTypeAuto:
      return "ReleaseTypeAuto";
    default:
      return "UNKNOWN";
  }
}

export interface Identification {
  SessionID: string;
}

export interface ReloadSession {
  SessionID: string;
}

export interface DestroySession {
  SessionID: string;
}

export interface UnfreezeSession {}

export interface FreezeSession {}

export interface GetSessionStatus {
  SessionID: string;
}

export interface SessionStatus {
  IsFrozen: boolean;
  IsAdmin: boolean;
  ExpiresAt: number;
}

export interface Session {
  SessionID: string;
  UserIdentifier: string;
  Status: SessionStatus | undefined;
}

export interface GetMetricsQuery {
  Since: Date | undefined;
  Until: Date | undefined;
}

export interface MetricsValue {
  Timestamp: Date | undefined;
  Value: number;
}

export interface MetricsBucket {
  Identifier: string;
  Values: MetricsValue[];
  Description: string;
  ValueType: MetricsValueType;
}

export interface GetMetricsResult {
  Buckets: MetricsBucket[];
}

export interface ListSubProcessesQuery {}

export interface RestartSubProcessesQuery {
  Identifier: string;
  NodeName: string;
}

export interface RestartSubProcessesResult {
  Success: boolean;
  Error: RestartSubProcessesError;
}

export interface RestartSubProcesses {
  Query: RestartSubProcessesQuery | undefined;
  Result: RestartSubProcessesResult | undefined;
}

export interface SubProcessNodeState {
  State: number;
  StateInfo: string;
  StateChangeTimestamp: Date | undefined;
  RunningSince: Date | undefined;
}

export interface SubProcessInfo {
  Identifier: string;
  Name: string;
  Description: string;
  Weight: number;
  NodeStates: { [key: string]: SubProcessNodeState };
}

export interface SubProcessInfo_NodeStatesEntry {
  key: string;
  value: SubProcessNodeState | undefined;
}

export interface ListSubProcessesResult {
  SubProcesses: SubProcessInfo[];
}

export interface ListQueries {}

export interface QueryDescription {
  QueryURL: string;
}

export interface QueryList {
  Queries: QueryDescription[];
}

export interface ListClients {}

export interface Client {
  Session: Session | undefined;
  HasEventConnection: boolean;
  RequestConnectionCount: number;
}

export interface ClientList {
  Clients: Client[];
}

export interface SessionRemoved {}

export interface SessionChanged {
  SessionID: string;
  Status: SessionStatus | undefined;
}

export interface EventSubscribe {
  Target: string;
  TargetType: string;
}

export interface EventUnsubscribe {
  Target: string;
  TargetType: string;
}

export interface Event {
  Target: string;
  TargetType: string;
  Payload: { [key: string]: Any };
}

export interface Event_PayloadEntry {
  key: string;
  value: Any | undefined;
}

export interface Push {
  Type: PushType;
  SessionID: string;
  Payload: { [key: string]: Any };
}

export interface Push_PayloadEntry {
  key: string;
  value: Any | undefined;
}

export interface ReleaseRequest {
  RequestID: string;
}

export interface Context {
  Values: { [key: string]: Any };
}

export interface Context_ValuesEntry {
  key: string;
  value: Any | undefined;
}

export interface Error {
  Code: number;
  Message: string;
}

export interface Response {
  Object: Any | undefined;
  RequestID: string;
  Error: Error | undefined;
  Diagnostics: Diagnostics | undefined;
  Releasable: boolean;
  HasNext: boolean;
}

export interface ReleaseOptions {
  Type: ReleaseType;
  AutoTTL: number;
}

export interface Request {
  Object: Any | undefined;
  RequestID: string;
  Context: Context | undefined;
  LanguageCode: string;
  ReleaseOptions: ReleaseOptions | undefined;
  DisableDiagnostics: boolean;
}

const baseIdentification: object = { SessionID: "" };

export const Identification = {
  encode(
    message: Identification,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.SessionID !== "") {
      writer.uint32(10).string(message.SessionID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Identification {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseIdentification } as Identification;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SessionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Identification {
    const message = { ...baseIdentification } as Identification;
    message.SessionID =
      object.SessionID !== undefined && object.SessionID !== null
        ? String(object.SessionID)
        : "";
    return message;
  },

  toJSON(message: Identification): unknown {
    const obj: any = {};
    message.SessionID !== undefined && (obj.SessionID = message.SessionID);
    return obj;
  },

  fromPartial(object: DeepPartial<Identification>): Identification {
    const message = { ...baseIdentification } as Identification;
    message.SessionID = object.SessionID ?? "";
    return message;
  },
};

const baseReloadSession: object = { SessionID: "" };

export const ReloadSession = {
  encode(
    message: ReloadSession,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.SessionID !== "") {
      writer.uint32(10).string(message.SessionID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReloadSession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReloadSession } as ReloadSession;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SessionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReloadSession {
    const message = { ...baseReloadSession } as ReloadSession;
    message.SessionID =
      object.SessionID !== undefined && object.SessionID !== null
        ? String(object.SessionID)
        : "";
    return message;
  },

  toJSON(message: ReloadSession): unknown {
    const obj: any = {};
    message.SessionID !== undefined && (obj.SessionID = message.SessionID);
    return obj;
  },

  fromPartial(object: DeepPartial<ReloadSession>): ReloadSession {
    const message = { ...baseReloadSession } as ReloadSession;
    message.SessionID = object.SessionID ?? "";
    return message;
  },
};

const baseDestroySession: object = { SessionID: "" };

export const DestroySession = {
  encode(
    message: DestroySession,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.SessionID !== "") {
      writer.uint32(10).string(message.SessionID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroySession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDestroySession } as DestroySession;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SessionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestroySession {
    const message = { ...baseDestroySession } as DestroySession;
    message.SessionID =
      object.SessionID !== undefined && object.SessionID !== null
        ? String(object.SessionID)
        : "";
    return message;
  },

  toJSON(message: DestroySession): unknown {
    const obj: any = {};
    message.SessionID !== undefined && (obj.SessionID = message.SessionID);
    return obj;
  },

  fromPartial(object: DeepPartial<DestroySession>): DestroySession {
    const message = { ...baseDestroySession } as DestroySession;
    message.SessionID = object.SessionID ?? "";
    return message;
  },
};

const baseUnfreezeSession: object = {};

export const UnfreezeSession = {
  encode(
    _: UnfreezeSession,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnfreezeSession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUnfreezeSession } as UnfreezeSession;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UnfreezeSession {
    const message = { ...baseUnfreezeSession } as UnfreezeSession;
    return message;
  },

  toJSON(_: UnfreezeSession): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<UnfreezeSession>): UnfreezeSession {
    const message = { ...baseUnfreezeSession } as UnfreezeSession;
    return message;
  },
};

const baseFreezeSession: object = {};

export const FreezeSession = {
  encode(
    _: FreezeSession,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FreezeSession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFreezeSession } as FreezeSession;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): FreezeSession {
    const message = { ...baseFreezeSession } as FreezeSession;
    return message;
  },

  toJSON(_: FreezeSession): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<FreezeSession>): FreezeSession {
    const message = { ...baseFreezeSession } as FreezeSession;
    return message;
  },
};

const baseGetSessionStatus: object = { SessionID: "" };

export const GetSessionStatus = {
  encode(
    message: GetSessionStatus,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.SessionID !== "") {
      writer.uint32(10).string(message.SessionID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSessionStatus } as GetSessionStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SessionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetSessionStatus {
    const message = { ...baseGetSessionStatus } as GetSessionStatus;
    message.SessionID =
      object.SessionID !== undefined && object.SessionID !== null
        ? String(object.SessionID)
        : "";
    return message;
  },

  toJSON(message: GetSessionStatus): unknown {
    const obj: any = {};
    message.SessionID !== undefined && (obj.SessionID = message.SessionID);
    return obj;
  },

  fromPartial(object: DeepPartial<GetSessionStatus>): GetSessionStatus {
    const message = { ...baseGetSessionStatus } as GetSessionStatus;
    message.SessionID = object.SessionID ?? "";
    return message;
  },
};

const baseSessionStatus: object = {
  IsFrozen: false,
  IsAdmin: false,
  ExpiresAt: 0,
};

export const SessionStatus = {
  encode(
    message: SessionStatus,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.IsFrozen === true) {
      writer.uint32(8).bool(message.IsFrozen);
    }
    if (message.IsAdmin === true) {
      writer.uint32(16).bool(message.IsAdmin);
    }
    if (message.ExpiresAt !== 0) {
      writer.uint32(24).int64(message.ExpiresAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSessionStatus } as SessionStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IsFrozen = reader.bool();
          break;
        case 2:
          message.IsAdmin = reader.bool();
          break;
        case 3:
          message.ExpiresAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionStatus {
    const message = { ...baseSessionStatus } as SessionStatus;
    message.IsFrozen =
      object.IsFrozen !== undefined && object.IsFrozen !== null
        ? Boolean(object.IsFrozen)
        : false;
    message.IsAdmin =
      object.IsAdmin !== undefined && object.IsAdmin !== null
        ? Boolean(object.IsAdmin)
        : false;
    message.ExpiresAt =
      object.ExpiresAt !== undefined && object.ExpiresAt !== null
        ? Number(object.ExpiresAt)
        : 0;
    return message;
  },

  toJSON(message: SessionStatus): unknown {
    const obj: any = {};
    message.IsFrozen !== undefined && (obj.IsFrozen = message.IsFrozen);
    message.IsAdmin !== undefined && (obj.IsAdmin = message.IsAdmin);
    message.ExpiresAt !== undefined && (obj.ExpiresAt = message.ExpiresAt);
    return obj;
  },

  fromPartial(object: DeepPartial<SessionStatus>): SessionStatus {
    const message = { ...baseSessionStatus } as SessionStatus;
    message.IsFrozen = object.IsFrozen ?? false;
    message.IsAdmin = object.IsAdmin ?? false;
    message.ExpiresAt = object.ExpiresAt ?? 0;
    return message;
  },
};

const baseSession: object = { SessionID: "", UserIdentifier: "" };

export const Session = {
  encode(
    message: Session,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.SessionID !== "") {
      writer.uint32(10).string(message.SessionID);
    }
    if (message.UserIdentifier !== "") {
      writer.uint32(18).string(message.UserIdentifier);
    }
    if (message.Status !== undefined) {
      SessionStatus.encode(message.Status, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Session {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSession } as Session;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SessionID = reader.string();
          break;
        case 2:
          message.UserIdentifier = reader.string();
          break;
        case 3:
          message.Status = SessionStatus.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Session {
    const message = { ...baseSession } as Session;
    message.SessionID =
      object.SessionID !== undefined && object.SessionID !== null
        ? String(object.SessionID)
        : "";
    message.UserIdentifier =
      object.UserIdentifier !== undefined && object.UserIdentifier !== null
        ? String(object.UserIdentifier)
        : "";
    message.Status =
      object.Status !== undefined && object.Status !== null
        ? SessionStatus.fromJSON(object.Status)
        : undefined;
    return message;
  },

  toJSON(message: Session): unknown {
    const obj: any = {};
    message.SessionID !== undefined && (obj.SessionID = message.SessionID);
    message.UserIdentifier !== undefined &&
      (obj.UserIdentifier = message.UserIdentifier);
    message.Status !== undefined &&
      (obj.Status = message.Status
        ? SessionStatus.toJSON(message.Status)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Session>): Session {
    const message = { ...baseSession } as Session;
    message.SessionID = object.SessionID ?? "";
    message.UserIdentifier = object.UserIdentifier ?? "";
    message.Status =
      object.Status !== undefined && object.Status !== null
        ? SessionStatus.fromPartial(object.Status)
        : undefined;
    return message;
  },
};

const baseGetMetricsQuery: object = {};

export const GetMetricsQuery = {
  encode(
    message: GetMetricsQuery,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Since !== undefined) {
      Timestamp.encode(
        toTimestamp(message.Since),
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.Until !== undefined) {
      Timestamp.encode(
        toTimestamp(message.Until),
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMetricsQuery {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetMetricsQuery } as GetMetricsQuery;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Since = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.Until = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetMetricsQuery {
    const message = { ...baseGetMetricsQuery } as GetMetricsQuery;
    message.Since =
      object.Since !== undefined && object.Since !== null
        ? fromJsonTimestamp(object.Since)
        : undefined;
    message.Until =
      object.Until !== undefined && object.Until !== null
        ? fromJsonTimestamp(object.Until)
        : undefined;
    return message;
  },

  toJSON(message: GetMetricsQuery): unknown {
    const obj: any = {};
    message.Since !== undefined && (obj.Since = message.Since.toISOString());
    message.Until !== undefined && (obj.Until = message.Until.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<GetMetricsQuery>): GetMetricsQuery {
    const message = { ...baseGetMetricsQuery } as GetMetricsQuery;
    message.Since = object.Since ?? undefined;
    message.Until = object.Until ?? undefined;
    return message;
  },
};

const baseMetricsValue: object = { Value: 0 };

export const MetricsValue = {
  encode(
    message: MetricsValue,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Timestamp !== undefined) {
      Timestamp.encode(
        toTimestamp(message.Timestamp),
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.Value !== 0) {
      writer.uint32(17).double(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MetricsValue {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMetricsValue } as MetricsValue;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Timestamp = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.Value = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MetricsValue {
    const message = { ...baseMetricsValue } as MetricsValue;
    message.Timestamp =
      object.Timestamp !== undefined && object.Timestamp !== null
        ? fromJsonTimestamp(object.Timestamp)
        : undefined;
    message.Value =
      object.Value !== undefined && object.Value !== null
        ? Number(object.Value)
        : 0;
    return message;
  },

  toJSON(message: MetricsValue): unknown {
    const obj: any = {};
    message.Timestamp !== undefined &&
      (obj.Timestamp = message.Timestamp.toISOString());
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial(object: DeepPartial<MetricsValue>): MetricsValue {
    const message = { ...baseMetricsValue } as MetricsValue;
    message.Timestamp = object.Timestamp ?? undefined;
    message.Value = object.Value ?? 0;
    return message;
  },
};

const baseMetricsBucket: object = {
  Identifier: "",
  Description: "",
  ValueType: 0,
};

export const MetricsBucket = {
  encode(
    message: MetricsBucket,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Identifier !== "") {
      writer.uint32(10).string(message.Identifier);
    }
    for (const v of message.Values) {
      MetricsValue.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.Description !== "") {
      writer.uint32(26).string(message.Description);
    }
    if (message.ValueType !== 0) {
      writer.uint32(32).int32(message.ValueType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MetricsBucket {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMetricsBucket } as MetricsBucket;
    message.Values = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Identifier = reader.string();
          break;
        case 2:
          message.Values.push(MetricsValue.decode(reader, reader.uint32()));
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.ValueType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MetricsBucket {
    const message = { ...baseMetricsBucket } as MetricsBucket;
    message.Identifier =
      object.Identifier !== undefined && object.Identifier !== null
        ? String(object.Identifier)
        : "";
    message.Values = (object.Values ?? []).map((e: any) =>
      MetricsValue.fromJSON(e)
    );
    message.Description =
      object.Description !== undefined && object.Description !== null
        ? String(object.Description)
        : "";
    message.ValueType =
      object.ValueType !== undefined && object.ValueType !== null
        ? metricsValueTypeFromJSON(object.ValueType)
        : 0;
    return message;
  },

  toJSON(message: MetricsBucket): unknown {
    const obj: any = {};
    message.Identifier !== undefined && (obj.Identifier = message.Identifier);
    if (message.Values) {
      obj.Values = message.Values.map((e) =>
        e ? MetricsValue.toJSON(e) : undefined
      );
    } else {
      obj.Values = [];
    }
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.ValueType !== undefined &&
      (obj.ValueType = metricsValueTypeToJSON(message.ValueType));
    return obj;
  },

  fromPartial(object: DeepPartial<MetricsBucket>): MetricsBucket {
    const message = { ...baseMetricsBucket } as MetricsBucket;
    message.Identifier = object.Identifier ?? "";
    message.Values = (object.Values ?? []).map((e) =>
      MetricsValue.fromPartial(e)
    );
    message.Description = object.Description ?? "";
    message.ValueType = object.ValueType ?? 0;
    return message;
  },
};

const baseGetMetricsResult: object = {};

export const GetMetricsResult = {
  encode(
    message: GetMetricsResult,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Buckets) {
      MetricsBucket.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMetricsResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetMetricsResult } as GetMetricsResult;
    message.Buckets = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Buckets.push(MetricsBucket.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetMetricsResult {
    const message = { ...baseGetMetricsResult } as GetMetricsResult;
    message.Buckets = (object.Buckets ?? []).map((e: any) =>
      MetricsBucket.fromJSON(e)
    );
    return message;
  },

  toJSON(message: GetMetricsResult): unknown {
    const obj: any = {};
    if (message.Buckets) {
      obj.Buckets = message.Buckets.map((e) =>
        e ? MetricsBucket.toJSON(e) : undefined
      );
    } else {
      obj.Buckets = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetMetricsResult>): GetMetricsResult {
    const message = { ...baseGetMetricsResult } as GetMetricsResult;
    message.Buckets = (object.Buckets ?? []).map((e) =>
      MetricsBucket.fromPartial(e)
    );
    return message;
  },
};

const baseListSubProcessesQuery: object = {};

export const ListSubProcessesQuery = {
  encode(
    _: ListSubProcessesQuery,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListSubProcessesQuery {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListSubProcessesQuery } as ListSubProcessesQuery;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ListSubProcessesQuery {
    const message = { ...baseListSubProcessesQuery } as ListSubProcessesQuery;
    return message;
  },

  toJSON(_: ListSubProcessesQuery): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ListSubProcessesQuery>): ListSubProcessesQuery {
    const message = { ...baseListSubProcessesQuery } as ListSubProcessesQuery;
    return message;
  },
};

const baseRestartSubProcessesQuery: object = { Identifier: "", NodeName: "" };

export const RestartSubProcessesQuery = {
  encode(
    message: RestartSubProcessesQuery,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Identifier !== "") {
      writer.uint32(10).string(message.Identifier);
    }
    if (message.NodeName !== "") {
      writer.uint32(18).string(message.NodeName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RestartSubProcessesQuery {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseRestartSubProcessesQuery,
    } as RestartSubProcessesQuery;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Identifier = reader.string();
          break;
        case 2:
          message.NodeName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RestartSubProcessesQuery {
    const message = {
      ...baseRestartSubProcessesQuery,
    } as RestartSubProcessesQuery;
    message.Identifier =
      object.Identifier !== undefined && object.Identifier !== null
        ? String(object.Identifier)
        : "";
    message.NodeName =
      object.NodeName !== undefined && object.NodeName !== null
        ? String(object.NodeName)
        : "";
    return message;
  },

  toJSON(message: RestartSubProcessesQuery): unknown {
    const obj: any = {};
    message.Identifier !== undefined && (obj.Identifier = message.Identifier);
    message.NodeName !== undefined && (obj.NodeName = message.NodeName);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RestartSubProcessesQuery>
  ): RestartSubProcessesQuery {
    const message = {
      ...baseRestartSubProcessesQuery,
    } as RestartSubProcessesQuery;
    message.Identifier = object.Identifier ?? "";
    message.NodeName = object.NodeName ?? "";
    return message;
  },
};

const baseRestartSubProcessesResult: object = { Success: false, Error: 0 };

export const RestartSubProcessesResult = {
  encode(
    message: RestartSubProcessesResult,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Success === true) {
      writer.uint32(8).bool(message.Success);
    }
    if (message.Error !== 0) {
      writer.uint32(16).int32(message.Error);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RestartSubProcessesResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseRestartSubProcessesResult,
    } as RestartSubProcessesResult;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Success = reader.bool();
          break;
        case 2:
          message.Error = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RestartSubProcessesResult {
    const message = {
      ...baseRestartSubProcessesResult,
    } as RestartSubProcessesResult;
    message.Success =
      object.Success !== undefined && object.Success !== null
        ? Boolean(object.Success)
        : false;
    message.Error =
      object.Error !== undefined && object.Error !== null
        ? restartSubProcessesErrorFromJSON(object.Error)
        : 0;
    return message;
  },

  toJSON(message: RestartSubProcessesResult): unknown {
    const obj: any = {};
    message.Success !== undefined && (obj.Success = message.Success);
    message.Error !== undefined &&
      (obj.Error = restartSubProcessesErrorToJSON(message.Error));
    return obj;
  },

  fromPartial(
    object: DeepPartial<RestartSubProcessesResult>
  ): RestartSubProcessesResult {
    const message = {
      ...baseRestartSubProcessesResult,
    } as RestartSubProcessesResult;
    message.Success = object.Success ?? false;
    message.Error = object.Error ?? 0;
    return message;
  },
};

const baseRestartSubProcesses: object = {};

export const RestartSubProcesses = {
  encode(
    message: RestartSubProcesses,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Query !== undefined) {
      RestartSubProcessesQuery.encode(
        message.Query,
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.Result !== undefined) {
      RestartSubProcessesResult.encode(
        message.Result,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestartSubProcesses {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRestartSubProcesses } as RestartSubProcesses;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Query = RestartSubProcessesQuery.decode(
            reader,
            reader.uint32()
          );
          break;
        case 2:
          message.Result = RestartSubProcessesResult.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RestartSubProcesses {
    const message = { ...baseRestartSubProcesses } as RestartSubProcesses;
    message.Query =
      object.Query !== undefined && object.Query !== null
        ? RestartSubProcessesQuery.fromJSON(object.Query)
        : undefined;
    message.Result =
      object.Result !== undefined && object.Result !== null
        ? RestartSubProcessesResult.fromJSON(object.Result)
        : undefined;
    return message;
  },

  toJSON(message: RestartSubProcesses): unknown {
    const obj: any = {};
    message.Query !== undefined &&
      (obj.Query = message.Query
        ? RestartSubProcessesQuery.toJSON(message.Query)
        : undefined);
    message.Result !== undefined &&
      (obj.Result = message.Result
        ? RestartSubProcessesResult.toJSON(message.Result)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<RestartSubProcesses>): RestartSubProcesses {
    const message = { ...baseRestartSubProcesses } as RestartSubProcesses;
    message.Query =
      object.Query !== undefined && object.Query !== null
        ? RestartSubProcessesQuery.fromPartial(object.Query)
        : undefined;
    message.Result =
      object.Result !== undefined && object.Result !== null
        ? RestartSubProcessesResult.fromPartial(object.Result)
        : undefined;
    return message;
  },
};

const baseSubProcessNodeState: object = { State: 0, StateInfo: "" };

export const SubProcessNodeState = {
  encode(
    message: SubProcessNodeState,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.State !== 0) {
      writer.uint32(8).int64(message.State);
    }
    if (message.StateInfo !== "") {
      writer.uint32(18).string(message.StateInfo);
    }
    if (message.StateChangeTimestamp !== undefined) {
      Timestamp.encode(
        toTimestamp(message.StateChangeTimestamp),
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.RunningSince !== undefined) {
      Timestamp.encode(
        toTimestamp(message.RunningSince),
        writer.uint32(34).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubProcessNodeState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSubProcessNodeState } as SubProcessNodeState;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.State = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.StateInfo = reader.string();
          break;
        case 3:
          message.StateChangeTimestamp = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.RunningSince = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubProcessNodeState {
    const message = { ...baseSubProcessNodeState } as SubProcessNodeState;
    message.State =
      object.State !== undefined && object.State !== null
        ? Number(object.State)
        : 0;
    message.StateInfo =
      object.StateInfo !== undefined && object.StateInfo !== null
        ? String(object.StateInfo)
        : "";
    message.StateChangeTimestamp =
      object.StateChangeTimestamp !== undefined &&
      object.StateChangeTimestamp !== null
        ? fromJsonTimestamp(object.StateChangeTimestamp)
        : undefined;
    message.RunningSince =
      object.RunningSince !== undefined && object.RunningSince !== null
        ? fromJsonTimestamp(object.RunningSince)
        : undefined;
    return message;
  },

  toJSON(message: SubProcessNodeState): unknown {
    const obj: any = {};
    message.State !== undefined && (obj.State = message.State);
    message.StateInfo !== undefined && (obj.StateInfo = message.StateInfo);
    message.StateChangeTimestamp !== undefined &&
      (obj.StateChangeTimestamp = message.StateChangeTimestamp.toISOString());
    message.RunningSince !== undefined &&
      (obj.RunningSince = message.RunningSince.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<SubProcessNodeState>): SubProcessNodeState {
    const message = { ...baseSubProcessNodeState } as SubProcessNodeState;
    message.State = object.State ?? 0;
    message.StateInfo = object.StateInfo ?? "";
    message.StateChangeTimestamp = object.StateChangeTimestamp ?? undefined;
    message.RunningSince = object.RunningSince ?? undefined;
    return message;
  },
};

const baseSubProcessInfo: object = {
  Identifier: "",
  Name: "",
  Description: "",
  Weight: 0,
};

export const SubProcessInfo = {
  encode(
    message: SubProcessInfo,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Identifier !== "") {
      writer.uint32(10).string(message.Identifier);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Description !== "") {
      writer.uint32(26).string(message.Description);
    }
    if (message.Weight !== 0) {
      writer.uint32(32).uint64(message.Weight);
    }
    Object.entries(message.NodeStates).forEach(([key, value]) => {
      SubProcessInfo_NodeStatesEntry.encode(
        { key: key as any, value },
        writer.uint32(42).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubProcessInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSubProcessInfo } as SubProcessInfo;
    message.NodeStates = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Identifier = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.Weight = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          const entry5 = SubProcessInfo_NodeStatesEntry.decode(
            reader,
            reader.uint32()
          );
          if (entry5.value !== undefined) {
            message.NodeStates[entry5.key] = entry5.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubProcessInfo {
    const message = { ...baseSubProcessInfo } as SubProcessInfo;
    message.Identifier =
      object.Identifier !== undefined && object.Identifier !== null
        ? String(object.Identifier)
        : "";
    message.Name =
      object.Name !== undefined && object.Name !== null
        ? String(object.Name)
        : "";
    message.Description =
      object.Description !== undefined && object.Description !== null
        ? String(object.Description)
        : "";
    message.Weight =
      object.Weight !== undefined && object.Weight !== null
        ? Number(object.Weight)
        : 0;
    message.NodeStates = Object.entries(object.NodeStates ?? {}).reduce<{
      [key: string]: SubProcessNodeState;
    }>((acc, [key, value]) => {
      acc[key] = SubProcessNodeState.fromJSON(value);
      return acc;
    }, {});
    return message;
  },

  toJSON(message: SubProcessInfo): unknown {
    const obj: any = {};
    message.Identifier !== undefined && (obj.Identifier = message.Identifier);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Weight !== undefined && (obj.Weight = message.Weight);
    obj.NodeStates = {};
    if (message.NodeStates) {
      Object.entries(message.NodeStates).forEach(([k, v]) => {
        obj.NodeStates[k] = SubProcessNodeState.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SubProcessInfo>): SubProcessInfo {
    const message = { ...baseSubProcessInfo } as SubProcessInfo;
    message.Identifier = object.Identifier ?? "";
    message.Name = object.Name ?? "";
    message.Description = object.Description ?? "";
    message.Weight = object.Weight ?? 0;
    message.NodeStates = Object.entries(object.NodeStates ?? {}).reduce<{
      [key: string]: SubProcessNodeState;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SubProcessNodeState.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

const baseSubProcessInfo_NodeStatesEntry: object = { key: "" };

export const SubProcessInfo_NodeStatesEntry = {
  encode(
    message: SubProcessInfo_NodeStatesEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      SubProcessNodeState.encode(
        message.value,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubProcessInfo_NodeStatesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseSubProcessInfo_NodeStatesEntry,
    } as SubProcessInfo_NodeStatesEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = SubProcessNodeState.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubProcessInfo_NodeStatesEntry {
    const message = {
      ...baseSubProcessInfo_NodeStatesEntry,
    } as SubProcessInfo_NodeStatesEntry;
    message.key =
      object.key !== undefined && object.key !== null ? String(object.key) : "";
    message.value =
      object.value !== undefined && object.value !== null
        ? SubProcessNodeState.fromJSON(object.value)
        : undefined;
    return message;
  },

  toJSON(message: SubProcessInfo_NodeStatesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? SubProcessNodeState.toJSON(message.value)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SubProcessInfo_NodeStatesEntry>
  ): SubProcessInfo_NodeStatesEntry {
    const message = {
      ...baseSubProcessInfo_NodeStatesEntry,
    } as SubProcessInfo_NodeStatesEntry;
    message.key = object.key ?? "";
    message.value =
      object.value !== undefined && object.value !== null
        ? SubProcessNodeState.fromPartial(object.value)
        : undefined;
    return message;
  },
};

const baseListSubProcessesResult: object = {};

export const ListSubProcessesResult = {
  encode(
    message: ListSubProcessesResult,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.SubProcesses) {
      SubProcessInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListSubProcessesResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListSubProcessesResult } as ListSubProcessesResult;
    message.SubProcesses = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SubProcesses.push(
            SubProcessInfo.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListSubProcessesResult {
    const message = { ...baseListSubProcessesResult } as ListSubProcessesResult;
    message.SubProcesses = (object.SubProcesses ?? []).map((e: any) =>
      SubProcessInfo.fromJSON(e)
    );
    return message;
  },

  toJSON(message: ListSubProcessesResult): unknown {
    const obj: any = {};
    if (message.SubProcesses) {
      obj.SubProcesses = message.SubProcesses.map((e) =>
        e ? SubProcessInfo.toJSON(e) : undefined
      );
    } else {
      obj.SubProcesses = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<ListSubProcessesResult>
  ): ListSubProcessesResult {
    const message = { ...baseListSubProcessesResult } as ListSubProcessesResult;
    message.SubProcesses = (object.SubProcesses ?? []).map((e) =>
      SubProcessInfo.fromPartial(e)
    );
    return message;
  },
};

const baseListQueries: object = {};

export const ListQueries = {
  encode(_: ListQueries, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListQueries {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListQueries } as ListQueries;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ListQueries {
    const message = { ...baseListQueries } as ListQueries;
    return message;
  },

  toJSON(_: ListQueries): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ListQueries>): ListQueries {
    const message = { ...baseListQueries } as ListQueries;
    return message;
  },
};

const baseQueryDescription: object = { QueryURL: "" };

export const QueryDescription = {
  encode(
    message: QueryDescription,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.QueryURL !== "") {
      writer.uint32(10).string(message.QueryURL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryDescription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseQueryDescription } as QueryDescription;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.QueryURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryDescription {
    const message = { ...baseQueryDescription } as QueryDescription;
    message.QueryURL =
      object.QueryURL !== undefined && object.QueryURL !== null
        ? String(object.QueryURL)
        : "";
    return message;
  },

  toJSON(message: QueryDescription): unknown {
    const obj: any = {};
    message.QueryURL !== undefined && (obj.QueryURL = message.QueryURL);
    return obj;
  },

  fromPartial(object: DeepPartial<QueryDescription>): QueryDescription {
    const message = { ...baseQueryDescription } as QueryDescription;
    message.QueryURL = object.QueryURL ?? "";
    return message;
  },
};

const baseQueryList: object = {};

export const QueryList = {
  encode(
    message: QueryList,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Queries) {
      QueryDescription.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryList {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseQueryList } as QueryList;
    message.Queries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Queries.push(
            QueryDescription.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryList {
    const message = { ...baseQueryList } as QueryList;
    message.Queries = (object.Queries ?? []).map((e: any) =>
      QueryDescription.fromJSON(e)
    );
    return message;
  },

  toJSON(message: QueryList): unknown {
    const obj: any = {};
    if (message.Queries) {
      obj.Queries = message.Queries.map((e) =>
        e ? QueryDescription.toJSON(e) : undefined
      );
    } else {
      obj.Queries = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<QueryList>): QueryList {
    const message = { ...baseQueryList } as QueryList;
    message.Queries = (object.Queries ?? []).map((e) =>
      QueryDescription.fromPartial(e)
    );
    return message;
  },
};

const baseListClients: object = {};

export const ListClients = {
  encode(_: ListClients, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListClients {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListClients } as ListClients;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ListClients {
    const message = { ...baseListClients } as ListClients;
    return message;
  },

  toJSON(_: ListClients): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ListClients>): ListClients {
    const message = { ...baseListClients } as ListClients;
    return message;
  },
};

const baseClient: object = {
  HasEventConnection: false,
  RequestConnectionCount: 0,
};

export const Client = {
  encode(
    message: Client,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Session !== undefined) {
      Session.encode(message.Session, writer.uint32(10).fork()).ldelim();
    }
    if (message.HasEventConnection === true) {
      writer.uint32(16).bool(message.HasEventConnection);
    }
    if (message.RequestConnectionCount !== 0) {
      writer.uint32(24).uint64(message.RequestConnectionCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseClient } as Client;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Session = Session.decode(reader, reader.uint32());
          break;
        case 2:
          message.HasEventConnection = reader.bool();
          break;
        case 3:
          message.RequestConnectionCount = longToNumber(
            reader.uint64() as Long
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Client {
    const message = { ...baseClient } as Client;
    message.Session =
      object.Session !== undefined && object.Session !== null
        ? Session.fromJSON(object.Session)
        : undefined;
    message.HasEventConnection =
      object.HasEventConnection !== undefined &&
      object.HasEventConnection !== null
        ? Boolean(object.HasEventConnection)
        : false;
    message.RequestConnectionCount =
      object.RequestConnectionCount !== undefined &&
      object.RequestConnectionCount !== null
        ? Number(object.RequestConnectionCount)
        : 0;
    return message;
  },

  toJSON(message: Client): unknown {
    const obj: any = {};
    message.Session !== undefined &&
      (obj.Session = message.Session
        ? Session.toJSON(message.Session)
        : undefined);
    message.HasEventConnection !== undefined &&
      (obj.HasEventConnection = message.HasEventConnection);
    message.RequestConnectionCount !== undefined &&
      (obj.RequestConnectionCount = message.RequestConnectionCount);
    return obj;
  },

  fromPartial(object: DeepPartial<Client>): Client {
    const message = { ...baseClient } as Client;
    message.Session =
      object.Session !== undefined && object.Session !== null
        ? Session.fromPartial(object.Session)
        : undefined;
    message.HasEventConnection = object.HasEventConnection ?? false;
    message.RequestConnectionCount = object.RequestConnectionCount ?? 0;
    return message;
  },
};

const baseClientList: object = {};

export const ClientList = {
  encode(
    message: ClientList,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Clients) {
      Client.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientList {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseClientList } as ClientList;
    message.Clients = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Clients.push(Client.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ClientList {
    const message = { ...baseClientList } as ClientList;
    message.Clients = (object.Clients ?? []).map((e: any) =>
      Client.fromJSON(e)
    );
    return message;
  },

  toJSON(message: ClientList): unknown {
    const obj: any = {};
    if (message.Clients) {
      obj.Clients = message.Clients.map((e) =>
        e ? Client.toJSON(e) : undefined
      );
    } else {
      obj.Clients = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ClientList>): ClientList {
    const message = { ...baseClientList } as ClientList;
    message.Clients = (object.Clients ?? []).map((e) => Client.fromPartial(e));
    return message;
  },
};

const baseSessionRemoved: object = {};

export const SessionRemoved = {
  encode(
    _: SessionRemoved,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionRemoved {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSessionRemoved } as SessionRemoved;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SessionRemoved {
    const message = { ...baseSessionRemoved } as SessionRemoved;
    return message;
  },

  toJSON(_: SessionRemoved): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<SessionRemoved>): SessionRemoved {
    const message = { ...baseSessionRemoved } as SessionRemoved;
    return message;
  },
};

const baseSessionChanged: object = { SessionID: "" };

export const SessionChanged = {
  encode(
    message: SessionChanged,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.SessionID !== "") {
      writer.uint32(10).string(message.SessionID);
    }
    if (message.Status !== undefined) {
      SessionStatus.encode(message.Status, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionChanged {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSessionChanged } as SessionChanged;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SessionID = reader.string();
          break;
        case 2:
          message.Status = SessionStatus.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionChanged {
    const message = { ...baseSessionChanged } as SessionChanged;
    message.SessionID =
      object.SessionID !== undefined && object.SessionID !== null
        ? String(object.SessionID)
        : "";
    message.Status =
      object.Status !== undefined && object.Status !== null
        ? SessionStatus.fromJSON(object.Status)
        : undefined;
    return message;
  },

  toJSON(message: SessionChanged): unknown {
    const obj: any = {};
    message.SessionID !== undefined && (obj.SessionID = message.SessionID);
    message.Status !== undefined &&
      (obj.Status = message.Status
        ? SessionStatus.toJSON(message.Status)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<SessionChanged>): SessionChanged {
    const message = { ...baseSessionChanged } as SessionChanged;
    message.SessionID = object.SessionID ?? "";
    message.Status =
      object.Status !== undefined && object.Status !== null
        ? SessionStatus.fromPartial(object.Status)
        : undefined;
    return message;
  },
};

const baseEventSubscribe: object = { Target: "", TargetType: "" };

export const EventSubscribe = {
  encode(
    message: EventSubscribe,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Target !== "") {
      writer.uint32(10).string(message.Target);
    }
    if (message.TargetType !== "") {
      writer.uint32(18).string(message.TargetType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventSubscribe {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventSubscribe } as EventSubscribe;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Target = reader.string();
          break;
        case 2:
          message.TargetType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventSubscribe {
    const message = { ...baseEventSubscribe } as EventSubscribe;
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? String(object.Target)
        : "";
    message.TargetType =
      object.TargetType !== undefined && object.TargetType !== null
        ? String(object.TargetType)
        : "";
    return message;
  },

  toJSON(message: EventSubscribe): unknown {
    const obj: any = {};
    message.Target !== undefined && (obj.Target = message.Target);
    message.TargetType !== undefined && (obj.TargetType = message.TargetType);
    return obj;
  },

  fromPartial(object: DeepPartial<EventSubscribe>): EventSubscribe {
    const message = { ...baseEventSubscribe } as EventSubscribe;
    message.Target = object.Target ?? "";
    message.TargetType = object.TargetType ?? "";
    return message;
  },
};

const baseEventUnsubscribe: object = { Target: "", TargetType: "" };

export const EventUnsubscribe = {
  encode(
    message: EventUnsubscribe,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Target !== "") {
      writer.uint32(10).string(message.Target);
    }
    if (message.TargetType !== "") {
      writer.uint32(18).string(message.TargetType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventUnsubscribe {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventUnsubscribe } as EventUnsubscribe;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Target = reader.string();
          break;
        case 2:
          message.TargetType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventUnsubscribe {
    const message = { ...baseEventUnsubscribe } as EventUnsubscribe;
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? String(object.Target)
        : "";
    message.TargetType =
      object.TargetType !== undefined && object.TargetType !== null
        ? String(object.TargetType)
        : "";
    return message;
  },

  toJSON(message: EventUnsubscribe): unknown {
    const obj: any = {};
    message.Target !== undefined && (obj.Target = message.Target);
    message.TargetType !== undefined && (obj.TargetType = message.TargetType);
    return obj;
  },

  fromPartial(object: DeepPartial<EventUnsubscribe>): EventUnsubscribe {
    const message = { ...baseEventUnsubscribe } as EventUnsubscribe;
    message.Target = object.Target ?? "";
    message.TargetType = object.TargetType ?? "";
    return message;
  },
};

const baseEvent: object = { Target: "", TargetType: "" };

export const Event = {
  encode(message: Event, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Target !== "") {
      writer.uint32(10).string(message.Target);
    }
    if (message.TargetType !== "") {
      writer.uint32(18).string(message.TargetType);
    }
    Object.entries(message.Payload).forEach(([key, value]) => {
      Event_PayloadEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEvent } as Event;
    message.Payload = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Target = reader.string();
          break;
        case 2:
          message.TargetType = reader.string();
          break;
        case 3:
          const entry3 = Event_PayloadEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.Payload[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Event {
    const message = { ...baseEvent } as Event;
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? String(object.Target)
        : "";
    message.TargetType =
      object.TargetType !== undefined && object.TargetType !== null
        ? String(object.TargetType)
        : "";
    message.Payload = Object.entries(object.Payload ?? {}).reduce<{
      [key: string]: Any;
    }>((acc, [key, value]) => {
      acc[key] = Any.fromJSON(value);
      return acc;
    }, {});
    return message;
  },

  toJSON(message: Event): unknown {
    const obj: any = {};
    message.Target !== undefined && (obj.Target = message.Target);
    message.TargetType !== undefined && (obj.TargetType = message.TargetType);
    obj.Payload = {};
    if (message.Payload) {
      Object.entries(message.Payload).forEach(([k, v]) => {
        obj.Payload[k] = Any.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Event>): Event {
    const message = { ...baseEvent } as Event;
    message.Target = object.Target ?? "";
    message.TargetType = object.TargetType ?? "";
    message.Payload = Object.entries(object.Payload ?? {}).reduce<{
      [key: string]: Any;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Any.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

const baseEvent_PayloadEntry: object = { key: "" };

export const Event_PayloadEntry = {
  encode(
    message: Event_PayloadEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Any.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PayloadEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEvent_PayloadEntry } as Event_PayloadEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = Any.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Event_PayloadEntry {
    const message = { ...baseEvent_PayloadEntry } as Event_PayloadEntry;
    message.key =
      object.key !== undefined && object.key !== null ? String(object.key) : "";
    message.value =
      object.value !== undefined && object.value !== null
        ? Any.fromJSON(object.value)
        : undefined;
    return message;
  },

  toJSON(message: Event_PayloadEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Any.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Event_PayloadEntry>): Event_PayloadEntry {
    const message = { ...baseEvent_PayloadEntry } as Event_PayloadEntry;
    message.key = object.key ?? "";
    message.value =
      object.value !== undefined && object.value !== null
        ? Any.fromPartial(object.value)
        : undefined;
    return message;
  },
};

const basePush: object = { Type: 0, SessionID: "" };

export const Push = {
  encode(message: Push, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Type !== 0) {
      writer.uint32(8).int32(message.Type);
    }
    if (message.SessionID !== "") {
      writer.uint32(18).string(message.SessionID);
    }
    Object.entries(message.Payload).forEach(([key, value]) => {
      Push_PayloadEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Push {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePush } as Push;
    message.Payload = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.int32() as any;
          break;
        case 2:
          message.SessionID = reader.string();
          break;
        case 3:
          const entry3 = Push_PayloadEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.Payload[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Push {
    const message = { ...basePush } as Push;
    message.Type =
      object.Type !== undefined && object.Type !== null
        ? pushTypeFromJSON(object.Type)
        : 0;
    message.SessionID =
      object.SessionID !== undefined && object.SessionID !== null
        ? String(object.SessionID)
        : "";
    message.Payload = Object.entries(object.Payload ?? {}).reduce<{
      [key: string]: Any;
    }>((acc, [key, value]) => {
      acc[key] = Any.fromJSON(value);
      return acc;
    }, {});
    return message;
  },

  toJSON(message: Push): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = pushTypeToJSON(message.Type));
    message.SessionID !== undefined && (obj.SessionID = message.SessionID);
    obj.Payload = {};
    if (message.Payload) {
      Object.entries(message.Payload).forEach(([k, v]) => {
        obj.Payload[k] = Any.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Push>): Push {
    const message = { ...basePush } as Push;
    message.Type = object.Type ?? 0;
    message.SessionID = object.SessionID ?? "";
    message.Payload = Object.entries(object.Payload ?? {}).reduce<{
      [key: string]: Any;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Any.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

const basePush_PayloadEntry: object = { key: "" };

export const Push_PayloadEntry = {
  encode(
    message: Push_PayloadEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Any.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Push_PayloadEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePush_PayloadEntry } as Push_PayloadEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = Any.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Push_PayloadEntry {
    const message = { ...basePush_PayloadEntry } as Push_PayloadEntry;
    message.key =
      object.key !== undefined && object.key !== null ? String(object.key) : "";
    message.value =
      object.value !== undefined && object.value !== null
        ? Any.fromJSON(object.value)
        : undefined;
    return message;
  },

  toJSON(message: Push_PayloadEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Any.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Push_PayloadEntry>): Push_PayloadEntry {
    const message = { ...basePush_PayloadEntry } as Push_PayloadEntry;
    message.key = object.key ?? "";
    message.value =
      object.value !== undefined && object.value !== null
        ? Any.fromPartial(object.value)
        : undefined;
    return message;
  },
};

const baseReleaseRequest: object = { RequestID: "" };

export const ReleaseRequest = {
  encode(
    message: ReleaseRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.RequestID !== "") {
      writer.uint32(10).string(message.RequestID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReleaseRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReleaseRequest } as ReleaseRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.RequestID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReleaseRequest {
    const message = { ...baseReleaseRequest } as ReleaseRequest;
    message.RequestID =
      object.RequestID !== undefined && object.RequestID !== null
        ? String(object.RequestID)
        : "";
    return message;
  },

  toJSON(message: ReleaseRequest): unknown {
    const obj: any = {};
    message.RequestID !== undefined && (obj.RequestID = message.RequestID);
    return obj;
  },

  fromPartial(object: DeepPartial<ReleaseRequest>): ReleaseRequest {
    const message = { ...baseReleaseRequest } as ReleaseRequest;
    message.RequestID = object.RequestID ?? "";
    return message;
  },
};

const baseContext: object = {};

export const Context = {
  encode(
    message: Context,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    Object.entries(message.Values).forEach(([key, value]) => {
      Context_ValuesEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Context {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseContext } as Context;
    message.Values = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry1 = Context_ValuesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.Values[entry1.key] = entry1.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Context {
    const message = { ...baseContext } as Context;
    message.Values = Object.entries(object.Values ?? {}).reduce<{
      [key: string]: Any;
    }>((acc, [key, value]) => {
      acc[key] = Any.fromJSON(value);
      return acc;
    }, {});
    return message;
  },

  toJSON(message: Context): unknown {
    const obj: any = {};
    obj.Values = {};
    if (message.Values) {
      Object.entries(message.Values).forEach(([k, v]) => {
        obj.Values[k] = Any.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Context>): Context {
    const message = { ...baseContext } as Context;
    message.Values = Object.entries(object.Values ?? {}).reduce<{
      [key: string]: Any;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Any.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

const baseContext_ValuesEntry: object = { key: "" };

export const Context_ValuesEntry = {
  encode(
    message: Context_ValuesEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Any.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Context_ValuesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseContext_ValuesEntry } as Context_ValuesEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = Any.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Context_ValuesEntry {
    const message = { ...baseContext_ValuesEntry } as Context_ValuesEntry;
    message.key =
      object.key !== undefined && object.key !== null ? String(object.key) : "";
    message.value =
      object.value !== undefined && object.value !== null
        ? Any.fromJSON(object.value)
        : undefined;
    return message;
  },

  toJSON(message: Context_ValuesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Any.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Context_ValuesEntry>): Context_ValuesEntry {
    const message = { ...baseContext_ValuesEntry } as Context_ValuesEntry;
    message.key = object.key ?? "";
    message.value =
      object.value !== undefined && object.value !== null
        ? Any.fromPartial(object.value)
        : undefined;
    return message;
  },
};

const baseError: object = { Code: 0, Message: "" };

export const Error = {
  encode(message: Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Code !== 0) {
      writer.uint32(8).int64(message.Code);
    }
    if (message.Message !== "") {
      writer.uint32(18).string(message.Message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Error {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseError } as Error;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Code = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.Message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Error {
    const message = { ...baseError } as Error;
    message.Code =
      object.Code !== undefined && object.Code !== null
        ? Number(object.Code)
        : 0;
    message.Message =
      object.Message !== undefined && object.Message !== null
        ? String(object.Message)
        : "";
    return message;
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    message.Code !== undefined && (obj.Code = message.Code);
    message.Message !== undefined && (obj.Message = message.Message);
    return obj;
  },

  fromPartial(object: DeepPartial<Error>): Error {
    const message = { ...baseError } as Error;
    message.Code = object.Code ?? 0;
    message.Message = object.Message ?? "";
    return message;
  },
};

const baseResponse: object = {
  RequestID: "",
  Releasable: false,
  HasNext: false,
};

export const Response = {
  encode(
    message: Response,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Object !== undefined) {
      Any.encode(message.Object, writer.uint32(10).fork()).ldelim();
    }
    if (message.RequestID !== "") {
      writer.uint32(18).string(message.RequestID);
    }
    if (message.Error !== undefined) {
      Error.encode(message.Error, writer.uint32(26).fork()).ldelim();
    }
    if (message.Diagnostics !== undefined) {
      Diagnostics.encode(
        message.Diagnostics,
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.Releasable === true) {
      writer.uint32(40).bool(message.Releasable);
    }
    if (message.HasNext === true) {
      writer.uint32(48).bool(message.HasNext);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Response {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseResponse } as Response;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Object = Any.decode(reader, reader.uint32());
          break;
        case 2:
          message.RequestID = reader.string();
          break;
        case 3:
          message.Error = Error.decode(reader, reader.uint32());
          break;
        case 4:
          message.Diagnostics = Diagnostics.decode(reader, reader.uint32());
          break;
        case 5:
          message.Releasable = reader.bool();
          break;
        case 6:
          message.HasNext = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Response {
    const message = { ...baseResponse } as Response;
    message.Object =
      object.Object !== undefined && object.Object !== null
        ? Any.fromJSON(object.Object)
        : undefined;
    message.RequestID =
      object.RequestID !== undefined && object.RequestID !== null
        ? String(object.RequestID)
        : "";
    message.Error =
      object.Error !== undefined && object.Error !== null
        ? Error.fromJSON(object.Error)
        : undefined;
    message.Diagnostics =
      object.Diagnostics !== undefined && object.Diagnostics !== null
        ? Diagnostics.fromJSON(object.Diagnostics)
        : undefined;
    message.Releasable =
      object.Releasable !== undefined && object.Releasable !== null
        ? Boolean(object.Releasable)
        : false;
    message.HasNext =
      object.HasNext !== undefined && object.HasNext !== null
        ? Boolean(object.HasNext)
        : false;
    return message;
  },

  toJSON(message: Response): unknown {
    const obj: any = {};
    message.Object !== undefined &&
      (obj.Object = message.Object ? Any.toJSON(message.Object) : undefined);
    message.RequestID !== undefined && (obj.RequestID = message.RequestID);
    message.Error !== undefined &&
      (obj.Error = message.Error ? Error.toJSON(message.Error) : undefined);
    message.Diagnostics !== undefined &&
      (obj.Diagnostics = message.Diagnostics
        ? Diagnostics.toJSON(message.Diagnostics)
        : undefined);
    message.Releasable !== undefined && (obj.Releasable = message.Releasable);
    message.HasNext !== undefined && (obj.HasNext = message.HasNext);
    return obj;
  },

  fromPartial(object: DeepPartial<Response>): Response {
    const message = { ...baseResponse } as Response;
    message.Object =
      object.Object !== undefined && object.Object !== null
        ? Any.fromPartial(object.Object)
        : undefined;
    message.RequestID = object.RequestID ?? "";
    message.Error =
      object.Error !== undefined && object.Error !== null
        ? Error.fromPartial(object.Error)
        : undefined;
    message.Diagnostics =
      object.Diagnostics !== undefined && object.Diagnostics !== null
        ? Diagnostics.fromPartial(object.Diagnostics)
        : undefined;
    message.Releasable = object.Releasable ?? false;
    message.HasNext = object.HasNext ?? false;
    return message;
  },
};

const baseReleaseOptions: object = { Type: 0, AutoTTL: 0 };

export const ReleaseOptions = {
  encode(
    message: ReleaseOptions,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Type !== 0) {
      writer.uint32(8).int32(message.Type);
    }
    if (message.AutoTTL !== 0) {
      writer.uint32(16).uint64(message.AutoTTL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReleaseOptions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReleaseOptions } as ReleaseOptions;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.int32() as any;
          break;
        case 2:
          message.AutoTTL = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReleaseOptions {
    const message = { ...baseReleaseOptions } as ReleaseOptions;
    message.Type =
      object.Type !== undefined && object.Type !== null
        ? releaseTypeFromJSON(object.Type)
        : 0;
    message.AutoTTL =
      object.AutoTTL !== undefined && object.AutoTTL !== null
        ? Number(object.AutoTTL)
        : 0;
    return message;
  },

  toJSON(message: ReleaseOptions): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = releaseTypeToJSON(message.Type));
    message.AutoTTL !== undefined && (obj.AutoTTL = message.AutoTTL);
    return obj;
  },

  fromPartial(object: DeepPartial<ReleaseOptions>): ReleaseOptions {
    const message = { ...baseReleaseOptions } as ReleaseOptions;
    message.Type = object.Type ?? 0;
    message.AutoTTL = object.AutoTTL ?? 0;
    return message;
  },
};

const baseRequest: object = {
  RequestID: "",
  LanguageCode: "",
  DisableDiagnostics: false,
};

export const Request = {
  encode(
    message: Request,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Object !== undefined) {
      Any.encode(message.Object, writer.uint32(10).fork()).ldelim();
    }
    if (message.RequestID !== "") {
      writer.uint32(18).string(message.RequestID);
    }
    if (message.Context !== undefined) {
      Context.encode(message.Context, writer.uint32(26).fork()).ldelim();
    }
    if (message.LanguageCode !== "") {
      writer.uint32(34).string(message.LanguageCode);
    }
    if (message.ReleaseOptions !== undefined) {
      ReleaseOptions.encode(
        message.ReleaseOptions,
        writer.uint32(42).fork()
      ).ldelim();
    }
    if (message.DisableDiagnostics === true) {
      writer.uint32(48).bool(message.DisableDiagnostics);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Request {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRequest } as Request;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Object = Any.decode(reader, reader.uint32());
          break;
        case 2:
          message.RequestID = reader.string();
          break;
        case 3:
          message.Context = Context.decode(reader, reader.uint32());
          break;
        case 4:
          message.LanguageCode = reader.string();
          break;
        case 5:
          message.ReleaseOptions = ReleaseOptions.decode(
            reader,
            reader.uint32()
          );
          break;
        case 6:
          message.DisableDiagnostics = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Request {
    const message = { ...baseRequest } as Request;
    message.Object =
      object.Object !== undefined && object.Object !== null
        ? Any.fromJSON(object.Object)
        : undefined;
    message.RequestID =
      object.RequestID !== undefined && object.RequestID !== null
        ? String(object.RequestID)
        : "";
    message.Context =
      object.Context !== undefined && object.Context !== null
        ? Context.fromJSON(object.Context)
        : undefined;
    message.LanguageCode =
      object.LanguageCode !== undefined && object.LanguageCode !== null
        ? String(object.LanguageCode)
        : "";
    message.ReleaseOptions =
      object.ReleaseOptions !== undefined && object.ReleaseOptions !== null
        ? ReleaseOptions.fromJSON(object.ReleaseOptions)
        : undefined;
    message.DisableDiagnostics =
      object.DisableDiagnostics !== undefined &&
      object.DisableDiagnostics !== null
        ? Boolean(object.DisableDiagnostics)
        : false;
    return message;
  },

  toJSON(message: Request): unknown {
    const obj: any = {};
    message.Object !== undefined &&
      (obj.Object = message.Object ? Any.toJSON(message.Object) : undefined);
    message.RequestID !== undefined && (obj.RequestID = message.RequestID);
    message.Context !== undefined &&
      (obj.Context = message.Context
        ? Context.toJSON(message.Context)
        : undefined);
    message.LanguageCode !== undefined &&
      (obj.LanguageCode = message.LanguageCode);
    message.ReleaseOptions !== undefined &&
      (obj.ReleaseOptions = message.ReleaseOptions
        ? ReleaseOptions.toJSON(message.ReleaseOptions)
        : undefined);
    message.DisableDiagnostics !== undefined &&
      (obj.DisableDiagnostics = message.DisableDiagnostics);
    return obj;
  },

  fromPartial(object: DeepPartial<Request>): Request {
    const message = { ...baseRequest } as Request;
    message.Object =
      object.Object !== undefined && object.Object !== null
        ? Any.fromPartial(object.Object)
        : undefined;
    message.RequestID = object.RequestID ?? "";
    message.Context =
      object.Context !== undefined && object.Context !== null
        ? Context.fromPartial(object.Context)
        : undefined;
    message.LanguageCode = object.LanguageCode ?? "";
    message.ReleaseOptions =
      object.ReleaseOptions !== undefined && object.ReleaseOptions !== null
        ? ReleaseOptions.fromPartial(object.ReleaseOptions)
        : undefined;
    message.DisableDiagnostics = object.DisableDiagnostics ?? false;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
