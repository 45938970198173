import { FC, ReactNode } from 'react';

// import { Link } from 'react-router-dom';

import './NavBar.scss';

type Props = {
    logo?: string;
    children: any;
};

const NavBar: FC<Props> = ({ logo, children }) => {

    return (

        <nav id='sticky-nav' className='sticky'>

            <div className='logo-container'>
                    {/* <Link to='/'>*/}{ logo ? <img className='logo' src={ logo } alt='Bedside'/> : <div className='title' >Bedside</div> }{/*</Link> */}
            </div>

            <div className='nav-items'>

                { children && children.length > 1 ? children!.map((child: ReactNode) => {

                    return (
                        <div className='nav-item'>{ child }</div>
                    )

                }) : <div className='nav-item'>{ children }</div> }

            </div>

        </nav>
    );

}
 
export default NavBar;