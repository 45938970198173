import * as any from './google/protobuf'
import * as bermuda from './Brightfish.EMR.Bermuda';
import * as transport from './Brightfish.EMR.Transport';

export interface IProtoJS {
    [test: string]: any
    [any.protobufPackage]: typeof any
    [bermuda.protobufPackage]: typeof bermuda
    [transport.protobufPackage]: typeof transport
}

const protoJS: IProtoJS = Object.create({});
protoJS[any.protobufPackage] = any;
protoJS[bermuda.protobufPackage] = bermuda;
protoJS[transport.protobufPackage] = transport;

export default protoJS;