import { DiscoveryModel } from 'models';
import { useEffect, useState } from 'react';
import { DiscoveryCategory, DiscoveryItem } from 'store';

const VideoViewModel = () => {
    const model = DiscoveryModel();
    const [structure, setStructure] = useState<DiscoveryCategory[]>([]);
    const [selectedItem, setSelectedItem] = useState<DiscoveryItem>();

    useEffect(() => {
        if (!structure.length) {
            (async () => {
                const structure = await model.getStructure('TVChannels');
                if (structure!.length) {
                    setStructure(structure!);
                    setSelectedItem(structure![0].items[0]);
                }
            })()
        }
    }, [model, structure]);

    const getContainerStructure = () => {
        return structure ?? [];
    }

    return {
        selectedItem,
        setSelectedItem,
        getContainerStructure,
        // getTitle
    }
}

export default VideoViewModel;
