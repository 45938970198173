import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CountlySingleton } from 'services';

const LocationView: FC<{ children: ReactNode }> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        CountlySingleton.shared.registerPageView(location.pathname);
    }, [location]);

    return (
        <>
            {children}
        </>
    );
}

export default LocationView;
