import React from 'react';

import './Card.scss';

type Props = {
    children: React.ReactNode;
    scroll?: boolean;
    // maxHeight: number;
    padding?: string;
};

const Card = ({ children, scroll, /*maxHeight,*/ padding = '1.5rem' }: Props) => {

    return (

        // <div className="responsive-container">
     
            <div className="column">

                <div key='cardRoot' className={scroll ? 'card scrollable' : 'card'} style={{/*maxHeight: maxHeight,*/ padding: padding}}>
                    <div key='cardInner'>
                        {children}
                    </div>
                </div>

            </div>

        // </div>

    );
};

export default Card;