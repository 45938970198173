/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Any } from "./google/protobuf/any";

export const protobufPackage = "Brightfish.EMR.Bermuda";

/** Source class: \Brightfish\Modules\Bermuda\Transferables\Option */
export interface Option {
  identifier: string;
  arrayValue: Any | undefined;
  name: string | undefined;
  type: string | undefined;
  boolValue: boolean | undefined;
  intValue: number | undefined;
  stringValue: string | undefined;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\Command */
export interface Command {
  identifier: string;
  name: string;
  type: string;
  parameters: Option[];
  options: Option[];
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\RunCommand */
export interface RunCommand {
  query: RunCommandQuery | undefined;
  result: RunCommandResult | undefined;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\RunCommandQuery */
export interface RunCommandQuery {
  identifier: string;
  parameters: Option[];
  options: Option[];
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\RunCommandResult */
export interface RunCommandResult {
  statusCode: number;
  success: boolean;
  errorMessage: string | undefined;
  requestableId: number | undefined;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\Status */
export interface Status {
  query: StatusQuery | undefined;
  result: StatusResult | undefined;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\StatusQuery */
export interface StatusQuery {
  requestableId: number;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\StatusResult */
export interface StatusResult {
  statusCode: number;
  completed: boolean;
  success: boolean;
  warningMessages: string[];
  errorMessage: string | undefined;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\Commands */
export interface Commands {
  query: CommandsQuery | undefined;
  result: CommandsResult | undefined;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\CommandsQuery */
export interface CommandsQuery {
  language: string | undefined;
}

/** Source class: \Brightfish\Modules\Bermuda\Transferables\CommandsResult */
export interface CommandsResult {
  commands: Command[];
}

const baseOption: object = { identifier: "" };

export const Option = {
  encode(
    message: Option,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.arrayValue !== undefined) {
      Any.encode(message.arrayValue, writer.uint32(58).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(26).string(message.type);
    }
    if (message.boolValue !== undefined) {
      writer.uint32(32).bool(message.boolValue);
    }
    if (message.intValue !== undefined) {
      writer.uint32(40).int64(message.intValue);
    }
    if (message.stringValue !== undefined) {
      writer.uint32(50).string(message.stringValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Option {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOption } as Option;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 7:
          message.arrayValue = Any.decode(reader, reader.uint32());
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.type = reader.string();
          break;
        case 4:
          message.boolValue = reader.bool();
          break;
        case 5:
          message.intValue = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.stringValue = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Option {
    const message = { ...baseOption } as Option;
    message.identifier =
      object.identifier !== undefined && object.identifier !== null
        ? String(object.identifier)
        : "";
    message.arrayValue =
      object.arrayValue !== undefined && object.arrayValue !== null
        ? Any.fromJSON(object.arrayValue)
        : undefined;
    message.name =
      object.name !== undefined && object.name !== null
        ? String(object.name)
        : undefined;
    message.type =
      object.type !== undefined && object.type !== null
        ? String(object.type)
        : undefined;
    message.boolValue =
      object.boolValue !== undefined && object.boolValue !== null
        ? Boolean(object.boolValue)
        : undefined;
    message.intValue =
      object.intValue !== undefined && object.intValue !== null
        ? Number(object.intValue)
        : undefined;
    message.stringValue =
      object.stringValue !== undefined && object.stringValue !== null
        ? String(object.stringValue)
        : undefined;
    return message;
  },

  toJSON(message: Option): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    message.arrayValue !== undefined &&
      (obj.arrayValue = message.arrayValue
        ? Any.toJSON(message.arrayValue)
        : undefined);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.boolValue !== undefined && (obj.boolValue = message.boolValue);
    message.intValue !== undefined && (obj.intValue = message.intValue);
    message.stringValue !== undefined &&
      (obj.stringValue = message.stringValue);
    return obj;
  },

  fromPartial(object: DeepPartial<Option>): Option {
    const message = { ...baseOption } as Option;
    message.identifier = object.identifier ?? "";
    message.arrayValue =
      object.arrayValue !== undefined && object.arrayValue !== null
        ? Any.fromPartial(object.arrayValue)
        : undefined;
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.boolValue = object.boolValue ?? undefined;
    message.intValue = object.intValue ?? undefined;
    message.stringValue = object.stringValue ?? undefined;
    return message;
  },
};

const baseCommand: object = { identifier: "", name: "", type: "" };

export const Command = {
  encode(
    message: Command,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    for (const v of message.parameters) {
      Option.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.options) {
      Option.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Command {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCommand } as Command;
    message.parameters = [];
    message.options = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.type = reader.string();
          break;
        case 4:
          message.parameters.push(Option.decode(reader, reader.uint32()));
          break;
        case 5:
          message.options.push(Option.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Command {
    const message = { ...baseCommand } as Command;
    message.identifier =
      object.identifier !== undefined && object.identifier !== null
        ? String(object.identifier)
        : "";
    message.name =
      object.name !== undefined && object.name !== null
        ? String(object.name)
        : "";
    message.type =
      object.type !== undefined && object.type !== null
        ? String(object.type)
        : "";
    message.parameters = (object.parameters ?? []).map((e: any) =>
      Option.fromJSON(e)
    );
    message.options = (object.options ?? []).map((e: any) =>
      Option.fromJSON(e)
    );
    return message;
  },

  toJSON(message: Command): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    if (message.parameters) {
      obj.parameters = message.parameters.map((e) =>
        e ? Option.toJSON(e) : undefined
      );
    } else {
      obj.parameters = [];
    }
    if (message.options) {
      obj.options = message.options.map((e) =>
        e ? Option.toJSON(e) : undefined
      );
    } else {
      obj.options = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Command>): Command {
    const message = { ...baseCommand } as Command;
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.parameters = (object.parameters ?? []).map((e) =>
      Option.fromPartial(e)
    );
    message.options = (object.options ?? []).map((e) => Option.fromPartial(e));
    return message;
  },
};

const baseRunCommand: object = {};

export const RunCommand = {
  encode(
    message: RunCommand,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.query !== undefined) {
      RunCommandQuery.encode(message.query, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      RunCommandResult.encode(
        message.result,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RunCommand {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRunCommand } as RunCommand;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.query = RunCommandQuery.decode(reader, reader.uint32());
          break;
        case 2:
          message.result = RunCommandResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RunCommand {
    const message = { ...baseRunCommand } as RunCommand;
    message.query =
      object.query !== undefined && object.query !== null
        ? RunCommandQuery.fromJSON(object.query)
        : undefined;
    message.result =
      object.result !== undefined && object.result !== null
        ? RunCommandResult.fromJSON(object.result)
        : undefined;
    return message;
  },

  toJSON(message: RunCommand): unknown {
    const obj: any = {};
    message.query !== undefined &&
      (obj.query = message.query
        ? RunCommandQuery.toJSON(message.query)
        : undefined);
    message.result !== undefined &&
      (obj.result = message.result
        ? RunCommandResult.toJSON(message.result)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<RunCommand>): RunCommand {
    const message = { ...baseRunCommand } as RunCommand;
    message.query =
      object.query !== undefined && object.query !== null
        ? RunCommandQuery.fromPartial(object.query)
        : undefined;
    message.result =
      object.result !== undefined && object.result !== null
        ? RunCommandResult.fromPartial(object.result)
        : undefined;
    return message;
  },
};

const baseRunCommandQuery: object = { identifier: "" };

export const RunCommandQuery = {
  encode(
    message: RunCommandQuery,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    for (const v of message.parameters) {
      Option.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.options) {
      Option.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RunCommandQuery {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRunCommandQuery } as RunCommandQuery;
    message.parameters = [];
    message.options = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.parameters.push(Option.decode(reader, reader.uint32()));
          break;
        case 3:
          message.options.push(Option.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RunCommandQuery {
    const message = { ...baseRunCommandQuery } as RunCommandQuery;
    message.identifier =
      object.identifier !== undefined && object.identifier !== null
        ? String(object.identifier)
        : "";
    message.parameters = (object.parameters ?? []).map((e: any) =>
      Option.fromJSON(e)
    );
    message.options = (object.options ?? []).map((e: any) =>
      Option.fromJSON(e)
    );
    return message;
  },

  toJSON(message: RunCommandQuery): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    if (message.parameters) {
      obj.parameters = message.parameters.map((e) =>
        e ? Option.toJSON(e) : undefined
      );
    } else {
      obj.parameters = [];
    }
    if (message.options) {
      obj.options = message.options.map((e) =>
        e ? Option.toJSON(e) : undefined
      );
    } else {
      obj.options = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<RunCommandQuery>): RunCommandQuery {
    const message = { ...baseRunCommandQuery } as RunCommandQuery;
    message.identifier = object.identifier ?? "";
    message.parameters = (object.parameters ?? []).map((e) =>
      Option.fromPartial(e)
    );
    message.options = (object.options ?? []).map((e) => Option.fromPartial(e));
    return message;
  },
};

const baseRunCommandResult: object = { statusCode: 0, success: false };

export const RunCommandResult = {
  encode(
    message: RunCommandResult,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int64(message.statusCode);
    }
    if (message.success === true) {
      writer.uint32(16).bool(message.success);
    }
    if (message.errorMessage !== undefined) {
      writer.uint32(26).string(message.errorMessage);
    }
    if (message.requestableId !== undefined) {
      writer.uint32(32).int64(message.requestableId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RunCommandResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRunCommandResult } as RunCommandResult;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusCode = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.success = reader.bool();
          break;
        case 3:
          message.errorMessage = reader.string();
          break;
        case 4:
          message.requestableId = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RunCommandResult {
    const message = { ...baseRunCommandResult } as RunCommandResult;
    message.statusCode =
      object.statusCode !== undefined && object.statusCode !== null
        ? Number(object.statusCode)
        : 0;
    message.success =
      object.success !== undefined && object.success !== null
        ? Boolean(object.success)
        : false;
    message.errorMessage =
      object.errorMessage !== undefined && object.errorMessage !== null
        ? String(object.errorMessage)
        : undefined;
    message.requestableId =
      object.requestableId !== undefined && object.requestableId !== null
        ? Number(object.requestableId)
        : undefined;
    return message;
  },

  toJSON(message: RunCommandResult): unknown {
    const obj: any = {};
    message.statusCode !== undefined && (obj.statusCode = message.statusCode);
    message.success !== undefined && (obj.success = message.success);
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.requestableId !== undefined &&
      (obj.requestableId = message.requestableId);
    return obj;
  },

  fromPartial(object: DeepPartial<RunCommandResult>): RunCommandResult {
    const message = { ...baseRunCommandResult } as RunCommandResult;
    message.statusCode = object.statusCode ?? 0;
    message.success = object.success ?? false;
    message.errorMessage = object.errorMessage ?? undefined;
    message.requestableId = object.requestableId ?? undefined;
    return message;
  },
};

const baseStatus: object = {};

export const Status = {
  encode(
    message: Status,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.query !== undefined) {
      StatusQuery.encode(message.query, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      StatusResult.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatus } as Status;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.query = StatusQuery.decode(reader, reader.uint32());
          break;
        case 2:
          message.result = StatusResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Status {
    const message = { ...baseStatus } as Status;
    message.query =
      object.query !== undefined && object.query !== null
        ? StatusQuery.fromJSON(object.query)
        : undefined;
    message.result =
      object.result !== undefined && object.result !== null
        ? StatusResult.fromJSON(object.result)
        : undefined;
    return message;
  },

  toJSON(message: Status): unknown {
    const obj: any = {};
    message.query !== undefined &&
      (obj.query = message.query
        ? StatusQuery.toJSON(message.query)
        : undefined);
    message.result !== undefined &&
      (obj.result = message.result
        ? StatusResult.toJSON(message.result)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Status>): Status {
    const message = { ...baseStatus } as Status;
    message.query =
      object.query !== undefined && object.query !== null
        ? StatusQuery.fromPartial(object.query)
        : undefined;
    message.result =
      object.result !== undefined && object.result !== null
        ? StatusResult.fromPartial(object.result)
        : undefined;
    return message;
  },
};

const baseStatusQuery: object = { requestableId: 0 };

export const StatusQuery = {
  encode(
    message: StatusQuery,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.requestableId !== 0) {
      writer.uint32(8).int64(message.requestableId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatusQuery {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatusQuery } as StatusQuery;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestableId = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StatusQuery {
    const message = { ...baseStatusQuery } as StatusQuery;
    message.requestableId =
      object.requestableId !== undefined && object.requestableId !== null
        ? Number(object.requestableId)
        : 0;
    return message;
  },

  toJSON(message: StatusQuery): unknown {
    const obj: any = {};
    message.requestableId !== undefined &&
      (obj.requestableId = message.requestableId);
    return obj;
  },

  fromPartial(object: DeepPartial<StatusQuery>): StatusQuery {
    const message = { ...baseStatusQuery } as StatusQuery;
    message.requestableId = object.requestableId ?? 0;
    return message;
  },
};

const baseStatusResult: object = {
  statusCode: 0,
  completed: false,
  success: false,
  warningMessages: "",
};

export const StatusResult = {
  encode(
    message: StatusResult,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int64(message.statusCode);
    }
    if (message.completed === true) {
      writer.uint32(16).bool(message.completed);
    }
    if (message.success === true) {
      writer.uint32(24).bool(message.success);
    }
    for (const v of message.warningMessages) {
      writer.uint32(34).string(v!);
    }
    if (message.errorMessage !== undefined) {
      writer.uint32(42).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatusResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatusResult } as StatusResult;
    message.warningMessages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusCode = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.completed = reader.bool();
          break;
        case 3:
          message.success = reader.bool();
          break;
        case 4:
          message.warningMessages.push(reader.string());
          break;
        case 5:
          message.errorMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StatusResult {
    const message = { ...baseStatusResult } as StatusResult;
    message.statusCode =
      object.statusCode !== undefined && object.statusCode !== null
        ? Number(object.statusCode)
        : 0;
    message.completed =
      object.completed !== undefined && object.completed !== null
        ? Boolean(object.completed)
        : false;
    message.success =
      object.success !== undefined && object.success !== null
        ? Boolean(object.success)
        : false;
    message.warningMessages = (object.warningMessages ?? []).map((e: any) =>
      String(e)
    );
    message.errorMessage =
      object.errorMessage !== undefined && object.errorMessage !== null
        ? String(object.errorMessage)
        : undefined;
    return message;
  },

  toJSON(message: StatusResult): unknown {
    const obj: any = {};
    message.statusCode !== undefined && (obj.statusCode = message.statusCode);
    message.completed !== undefined && (obj.completed = message.completed);
    message.success !== undefined && (obj.success = message.success);
    if (message.warningMessages) {
      obj.warningMessages = message.warningMessages.map((e) => e);
    } else {
      obj.warningMessages = [];
    }
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial(object: DeepPartial<StatusResult>): StatusResult {
    const message = { ...baseStatusResult } as StatusResult;
    message.statusCode = object.statusCode ?? 0;
    message.completed = object.completed ?? false;
    message.success = object.success ?? false;
    message.warningMessages = (object.warningMessages ?? []).map((e) => e);
    message.errorMessage = object.errorMessage ?? undefined;
    return message;
  },
};

const baseCommands: object = {};

export const Commands = {
  encode(
    message: Commands,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.query !== undefined) {
      CommandsQuery.encode(message.query, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      CommandsResult.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commands {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCommands } as Commands;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.query = CommandsQuery.decode(reader, reader.uint32());
          break;
        case 2:
          message.result = CommandsResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Commands {
    const message = { ...baseCommands } as Commands;
    message.query =
      object.query !== undefined && object.query !== null
        ? CommandsQuery.fromJSON(object.query)
        : undefined;
    message.result =
      object.result !== undefined && object.result !== null
        ? CommandsResult.fromJSON(object.result)
        : undefined;
    return message;
  },

  toJSON(message: Commands): unknown {
    const obj: any = {};
    message.query !== undefined &&
      (obj.query = message.query
        ? CommandsQuery.toJSON(message.query)
        : undefined);
    message.result !== undefined &&
      (obj.result = message.result
        ? CommandsResult.toJSON(message.result)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Commands>): Commands {
    const message = { ...baseCommands } as Commands;
    message.query =
      object.query !== undefined && object.query !== null
        ? CommandsQuery.fromPartial(object.query)
        : undefined;
    message.result =
      object.result !== undefined && object.result !== null
        ? CommandsResult.fromPartial(object.result)
        : undefined;
    return message;
  },
};

const baseCommandsQuery: object = {};

export const CommandsQuery = {
  encode(
    message: CommandsQuery,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.language !== undefined) {
      writer.uint32(10).string(message.language);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommandsQuery {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCommandsQuery } as CommandsQuery;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.language = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CommandsQuery {
    const message = { ...baseCommandsQuery } as CommandsQuery;
    message.language =
      object.language !== undefined && object.language !== null
        ? String(object.language)
        : undefined;
    return message;
  },

  toJSON(message: CommandsQuery): unknown {
    const obj: any = {};
    message.language !== undefined && (obj.language = message.language);
    return obj;
  },

  fromPartial(object: DeepPartial<CommandsQuery>): CommandsQuery {
    const message = { ...baseCommandsQuery } as CommandsQuery;
    message.language = object.language ?? undefined;
    return message;
  },
};

const baseCommandsResult: object = {};

export const CommandsResult = {
  encode(
    message: CommandsResult,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.commands) {
      Command.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommandsResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCommandsResult } as CommandsResult;
    message.commands = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.commands.push(Command.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CommandsResult {
    const message = { ...baseCommandsResult } as CommandsResult;
    message.commands = (object.commands ?? []).map((e: any) =>
      Command.fromJSON(e)
    );
    return message;
  },

  toJSON(message: CommandsResult): unknown {
    const obj: any = {};
    if (message.commands) {
      obj.commands = message.commands.map((e) =>
        e ? Command.toJSON(e) : undefined
      );
    } else {
      obj.commands = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<CommandsResult>): CommandsResult {
    const message = { ...baseCommandsResult } as CommandsResult;
    message.commands = (object.commands ?? []).map((e) =>
      Command.fromPartial(e)
    );
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
