import { BedsideService } from 'services';
import { DiscoveryCategory, useStore } from 'store';

export const DiscoveryModel = () => {
    const store = useStore();
    const service: BedsideService = new BedsideService();

    const getStructure = async (containerName: string) => {
        if (store.containers.hasOwnProperty(containerName)) return store.containers[containerName];

        const responseContainer = await service.getContainer(containerName);

        const sortedStructure = (responseContainer?.payload.structure ?? []).map((category: IDiscoveryCategory) => {
            category.items.sort((a: { sort: number; }, b: { sort: number; }) => (a.sort > b.sort) ? 1 : -1);
            return category;
        });

        const container = sortedStructure.map(async (category: IDiscoveryCategory) => await DiscoveryCategory.build(category));
        const resolvedContainer: DiscoveryCategory[] = await Promise.all(container);

        if (resolvedContainer.length) {
            store.addContainer(containerName, resolvedContainer);
        }

        return resolvedContainer;
    }

    return {
        getStructure
    }
}