import { iAppModel } from 'models';
import { FC } from 'react';

import NavigationViewModel from '../viewModel';
import NavBarView from './NavBarView';
import TabBarView from './TabBarView';

const NavigationView: FC<{ model: iAppModel }> = ({ model }) => {
    const viewModel = NavigationViewModel(model);
    return (
        <div>
            <NavBarView viewModel={viewModel} />
            <TabBarView viewModel={viewModel} />
        </div>
    );
}

export default NavigationView;
