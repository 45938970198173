import React from 'react';

import '../Tab.scss';

type Props = {
    children: React.ReactNode,
    onClick: React.MouseEventHandler
    path: string,
    currentPage: string,
};

const TabItem = ({ children, onClick, path, currentPage }: Props) => {
    return (
        <div 
            className={ path === currentPage ? 'tab-item tab-item-select' : 'tab-item' } 
            onClick={ onClick }
        >
            { children }
        </div>
        
    )
}

export default TabItem