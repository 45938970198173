import { createContext, useContext } from 'react';

export interface iAssets {
    header: string;
    logo: string;
    favicon: string;
    login: string;
}

const AssetContext = createContext<
    iAssets | undefined
>(undefined)

const AssetProvider = ({ assets, children }: { assets: iAssets, children: React.ReactNode }) => {
    return (
        <AssetContext.Provider
            value={assets}
        >
            {children}
        </AssetContext.Provider>
    );
};

const useAssets = () => {
    const context = useContext(AssetContext);
    if (context === undefined) {
        throw new Error('useAssets must be used within a AssetProvider');
    }
    return context;
}

export { AssetProvider, useAssets }