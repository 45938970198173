import { AppSubstrateConfig, KEY_SERVER, RESPONSE_ARRAYBUFFER, RESPONSE_CONTAINER, RESPONSE_PROTOBUF } from './types';

import { UserAuthentication } from '../Authentication';

import { RequestBuilder } from './Request';
import { DataStore } from './DataStore';
import { Crypto } from './Crypto';

// TODO: use Protobuf dir any?
import { Any } from 'google-protobuf/google/protobuf/any_pb';
import { protoJS } from '../Protobuf';

export class AppSubstrate {

    private static store: DataStore;
    static crypto: Crypto;

    static authenticationModule: UserAuthentication; // TODO: expand for different authentication modules, SSO/voucherbased?

    static initialized: boolean = false;

    get builder(): RequestBuilder {
        const builder = new RequestBuilder();
        builder.userKey = AppSubstrate.store.userKey;
        builder.passwordKey = AppSubstrate.store.passwordKey;
        return builder;
    }

    static initialize = async (
        config: AppSubstrateConfig,
        store: DataStore = new DataStore(),
        crypto: Crypto = new Crypto()
    ) => {
        RequestBuilder.url = config.url;
        RequestBuilder.clientAccount = config.clientAccount;
        RequestBuilder.clientBrand = config.clientBrand;
        RequestBuilder.clientSubBrand = config.clientSubBrand;
        RequestBuilder.version = config.version;

        this.store = store;
        this.crypto = crypto;

        const instance = new AppSubstrate();
        const builder: RequestBuilder = instance.builder;
        builder.category = 'GetServerKey';
        const response = await instance.run(builder);
        this.store.serverKey = response.payload.serverKey;

        this.authenticationModule = new UserAuthentication(this.store, this.crypto);

        this.initialized = true;
    }

    static login = async (
        username: string,
        password: string,
        multiFactorTokens: {} | undefined = undefined
    ) => {
        return await AppSubstrate.authenticationModule.performLogin(username, password, multiFactorTokens);
    }

    static logout = () => {
        AppSubstrate.authenticationModule.performLogout();
    }

    run = async (builder: RequestBuilder) => {
        const request = builder.build();
        const response = await request.fetch();
        return this.handleResponse(response, builder.responseType);
    }

    handleResponse = async (fetchResponse: Response, responseType: string) => {

        // TODO: validate response properly
        // validateResponse(fetchResponse) // performContainerRequest (SF AppSubstrate)
        if (fetchResponse.status !== 200) {
            throw new Error(fetchResponse.statusText);
        }

        switch (responseType) {
            case RESPONSE_CONTAINER:
                return await this.handleContainerResponse(fetchResponse);
            case RESPONSE_PROTOBUF:
                return await this.handleProtobufResponse(fetchResponse);
            case RESPONSE_ARRAYBUFFER:
                return await this.handleArrayBufferResponse(fetchResponse);
        }
    }

    handleContainerResponse = async (fetchResponse: Response) => {
        const backupResponse = fetchResponse.clone();

        let container;
        try {
            container = await fetchResponse!.json();
        } catch (e) {
            console.log(e);
            const rawResponseText = Object.keys(backupResponse).length ? await backupResponse.text() : '';
            console.warn('Request failed with plaintext response:', rawResponseText);
            return;
        }

        // encrypted payloads?

        return container;
    }

    handleProtobufResponse = async (fetchResponse: Response) => {
        const container = await this.handleContainerResponse(fetchResponse);

        const buffer = AppSubstrate.crypto.convertHexToBuffer(container.payload);
        const any = Any.deserializeBinary(buffer);

        const pathArray = any.getTypeName().split(/\.(?=[^.]+$)/);
        const proto = pathArray?.reduce((result, key) => result && result[key], protoJS);

        return proto.decode(any.getValue() as Uint8Array);
    }

    handleArrayBufferResponse = async (fetchResponse: Response) => {
        const backupResponse = fetchResponse.clone();

        const encryptionKeyType = backupResponse!.headers.has('X-AppSubstrate-EncryptionKeyType') ? backupResponse!.headers.get('X-AppSubstrate-EncryptionKeyType') : null;
        const encrypted = backupResponse!.headers.has('X-AppSubstrate-Encrypted') ? (backupResponse!.headers.get('X-AppSubstrate-Encrypted') === 'true') : null;

        let responseArrayBuffer;
        if (encrypted) {
            const key = encryptionKeyType === KEY_SERVER ? AppSubstrate.store.serverKey : AppSubstrate.store.userKey;
            responseArrayBuffer = AppSubstrate.crypto.decryptStringToArrayBuffer(encryptionKeyType, key, await backupResponse!.text());
        } else {
            responseArrayBuffer = await backupResponse!.arrayBuffer();
        }

        return responseArrayBuffer;
    }
}
