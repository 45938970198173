import React, { FC, useState, useEffect, createRef } from 'react';

import icons from 'assets/svg/bf-icons.svg';
import './Modal.scss';

export interface ModalProps {
    handleClose: () => void
    show: boolean
    closeButtonEnabled?: boolean
}

const Modal: FC<ModalProps> = ({ handleClose, show, children, closeButtonEnabled = true }) => {
    const [showModal, setShowModal] = useState(show);
    const node = createRef<HTMLDivElement>();
    const showHideClassName = showModal ? 'modal display-block' : 'modal display-none';

    useEffect(() => {
        setShowModal(show);

        document.addEventListener('keydown', handleEscape, false);

        return () => {
            document.removeEventListener('keydown', handleEscape, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const handleClick = (e: any) => {
        e.stopPropagation();
        if (node.current!.contains(e.target)) {
            return
        }
        else {
            handleClose();
        }
    }

    const handleEscape = (e: { key: any; keyCode: any; }) => {
        let key = e.key || e.keyCode;
        if (key === 'Escape' || key === 27) {
            handleClose();
        }
    }

    return (
        <div onClick={handleClick} className={showHideClassName}>
            <section ref={node} className='modal-main'>
                <div>
                <div>
                    {closeButtonEnabled
                        ?
                        <div className='modal-close-button'>
                            <svg onClick={(e) => { e.stopPropagation(); handleClose() }} className='close-button'>
                                <use xlinkHref={`${icons}#cross`} />
                            </svg>
                        </div>
                        :
                        null
                    }
                </div>
                <div>
                    {children}
                </div>
                </div>
            </section>
        </div>
    );
};

export default Modal;