import { useAssets } from "context";

const IntroPageViewModel = () => {
    const assets = useAssets();

    const getAssets = () => {
        return assets;
    }

    return {
        getAssets
    }
}

export default IntroPageViewModel;