import React from 'react';

import '../Dropdown.scss';

type Props = {
    onClick: React.MouseEventHandler,
    label: string,
    selected?: boolean,
};

const DropdownItem = ({ onClick, label, selected = false }: Props) => {

    return (
        <li className={'dropdown-option' + (selected ? ' selected' : '')} onClick={ onClick } >
            { label }
        </li>
    );

}

export default DropdownItem;