import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Spinner } from 'framework';
import { T } from 'localization';
import { DiscoveryCategory, DiscoveryItem } from 'store';

import VideoViewModel from '../viewModel';

const VideoView: FC = observer(() => {
    const viewModel = VideoViewModel();

    return (
        <div className='responsive-container'>

            {!viewModel.getContainerStructure().length ?
                <div className='discovery-spinner'>
                    <Spinner />
                </div>
                : <div>
                    <h4 className='headline'>{`${T('discovery.television.title')}: ${viewModel.selectedItem?.getTitle() ?? ''}`}</h4>

                    {viewModel.getContainerStructure().map((struct: DiscoveryCategory) => (
                        <Card key={struct.id} padding={'none'}>

                            <video width='100%' height='100%' key={viewModel.selectedItem?.id ?? 0} controls autoPlay>
                                <source src={viewModel.selectedItem?.link.stream_url! ?? ''} />
                            </video>

                            {struct.items.map((item: DiscoveryItem) => (
                                <div key={item.id} className={`discovery-item ${viewModel.selectedItem?.id === item.id ? 'selected' : ''} separator`} onClick={() => viewModel.setSelectedItem(item)}>
                                    <div className='row'>
                                        <div className='row-item'>
                                            {item.image?.getImage() ? <div className='icon-container'><img className='icon' src={item.image?.getImage()} alt=''></img></div> : null}
                                        </div>
                                        <div className='row-item'>
                                            {item.getTitle()}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </Card>
                    ))}
                </div>}

        </div>
    );
});

export default VideoView;
