import { useHistory, useLocation } from 'react-router';
import { iAppModel } from 'models';

const AppViewModel = (model: iAppModel) => {
    const history = useHistory();
    const location = useLocation();

    const getAssets = () => {
        return model.getAssets();
    }

    const getConfig = () => {
        return model.getConfig();
    }

    const getBranding = () => {
        return getConfig().branding;
    }

    const getLocation = () => {
        return location;
    }

    const getTransitionDirection = () => {
        const state = history.location.state as { currentPath: number, previousPath: number };

        const currentPath = state ? state.currentPath : 0;
        const previousPath = state ? state.previousPath : 1;

        return currentPath > previousPath ? 'left' : 'right';
    }

    const fetchTranslations = ({ language = 'nl' }) => {
        const translations = model.getTranslations();
        return translations;
        // return translations[language];
    }

    return {
        getAssets,
        getConfig,
        getBranding,
        getLocation,
        getTransitionDirection,
        fetchTranslations
    }
}

export default AppViewModel;