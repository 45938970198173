import { singleton } from 'localization';

const DiscoveryTranslator = (translations: IDiscoveryTranslation[], accessor: string) => {
    const { language } = singleton;

    const translation = translations.find((translation) => translation.language_code === language);
    
    switch (accessor) {
        case 'title':
            return translation?.title;
        case 'description':
            return translation?.description;
    }
}

export default DiscoveryTranslator;