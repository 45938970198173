/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "Brightfish.Transport.Performance";

export interface Measurement {
  Label: string;
  Start: number;
  End: number;
  Color: number;
  Measurements: Measurement[];
}

export interface Diagnostics {
  Measurements: Measurement[];
  Manager: string;
  Worker: string;
}

const baseMeasurement: object = { Label: "", Start: 0, End: 0, Color: 0 };

export const Measurement = {
  encode(
    message: Measurement,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Label !== "") {
      writer.uint32(10).string(message.Label);
    }
    if (message.Start !== 0) {
      writer.uint32(21).float(message.Start);
    }
    if (message.End !== 0) {
      writer.uint32(29).float(message.End);
    }
    if (message.Color !== 0) {
      writer.uint32(32).uint32(message.Color);
    }
    for (const v of message.Measurements) {
      Measurement.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Measurement {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMeasurement } as Measurement;
    message.Measurements = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Label = reader.string();
          break;
        case 2:
          message.Start = reader.float();
          break;
        case 3:
          message.End = reader.float();
          break;
        case 4:
          message.Color = reader.uint32();
          break;
        case 5:
          message.Measurements.push(
            Measurement.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Measurement {
    const message = { ...baseMeasurement } as Measurement;
    message.Label =
      object.Label !== undefined && object.Label !== null
        ? String(object.Label)
        : "";
    message.Start =
      object.Start !== undefined && object.Start !== null
        ? Number(object.Start)
        : 0;
    message.End =
      object.End !== undefined && object.End !== null ? Number(object.End) : 0;
    message.Color =
      object.Color !== undefined && object.Color !== null
        ? Number(object.Color)
        : 0;
    message.Measurements = (object.Measurements ?? []).map((e: any) =>
      Measurement.fromJSON(e)
    );
    return message;
  },

  toJSON(message: Measurement): unknown {
    const obj: any = {};
    message.Label !== undefined && (obj.Label = message.Label);
    message.Start !== undefined && (obj.Start = message.Start);
    message.End !== undefined && (obj.End = message.End);
    message.Color !== undefined && (obj.Color = message.Color);
    if (message.Measurements) {
      obj.Measurements = message.Measurements.map((e) =>
        e ? Measurement.toJSON(e) : undefined
      );
    } else {
      obj.Measurements = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Measurement>): Measurement {
    const message = { ...baseMeasurement } as Measurement;
    message.Label = object.Label ?? "";
    message.Start = object.Start ?? 0;
    message.End = object.End ?? 0;
    message.Color = object.Color ?? 0;
    message.Measurements = (object.Measurements ?? []).map((e) =>
      Measurement.fromPartial(e)
    );
    return message;
  },
};

const baseDiagnostics: object = { Manager: "", Worker: "" };

export const Diagnostics = {
  encode(
    message: Diagnostics,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Measurements) {
      Measurement.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Manager !== "") {
      writer.uint32(18).string(message.Manager);
    }
    if (message.Worker !== "") {
      writer.uint32(26).string(message.Worker);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Diagnostics {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDiagnostics } as Diagnostics;
    message.Measurements = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Measurements.push(
            Measurement.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.Manager = reader.string();
          break;
        case 3:
          message.Worker = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Diagnostics {
    const message = { ...baseDiagnostics } as Diagnostics;
    message.Measurements = (object.Measurements ?? []).map((e: any) =>
      Measurement.fromJSON(e)
    );
    message.Manager =
      object.Manager !== undefined && object.Manager !== null
        ? String(object.Manager)
        : "";
    message.Worker =
      object.Worker !== undefined && object.Worker !== null
        ? String(object.Worker)
        : "";
    return message;
  },

  toJSON(message: Diagnostics): unknown {
    const obj: any = {};
    if (message.Measurements) {
      obj.Measurements = message.Measurements.map((e) =>
        e ? Measurement.toJSON(e) : undefined
      );
    } else {
      obj.Measurements = [];
    }
    message.Manager !== undefined && (obj.Manager = message.Manager);
    message.Worker !== undefined && (obj.Worker = message.Worker);
    return obj;
  },

  fromPartial(object: DeepPartial<Diagnostics>): Diagnostics {
    const message = { ...baseDiagnostics } as Diagnostics;
    message.Measurements = (object.Measurements ?? []).map((e) =>
      Measurement.fromPartial(e)
    );
    message.Manager = object.Manager ?? "";
    message.Worker = object.Worker ?? "";
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
