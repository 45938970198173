import DiscoveryImage from './DiscoveryImage';
import DiscoveryTranslator from './DiscoveryTranslator';

class DiscoveryItem {
    id: number;
    title: string;
    translations: IDiscoveryTranslation[];
    image?: DiscoveryImage;
    link: IDiscoveryLink;

    constructor(item: IDiscoveryItem) {
        this.id = item.id;
        this.title = item.internal_title;
        this.translations = item.translations;
        this.link = item.link;
    }

    static async build(item: IDiscoveryItem) {
        const self = new DiscoveryItem(item);
        self.image = item.image ? await DiscoveryImage.build(item.image) : undefined;
        return self;
    }

    getTitle() {
        return DiscoveryTranslator(this.translations, 'title') ?? this.title;
    }

    getDescription() {
        return DiscoveryTranslator(this.translations, 'description');
    }

}

export default DiscoveryItem;