import { makeObservable, observable, runInAction } from "mobx";
import { BedsideService } from "services";

export const APPLICATION_ACROBAT = 'application/acrobat';
export const APPLICATION_PDF = 'application/pdf';
export const VIDEO_MP4 = 'video/mp4';

export class DocumentViewModel {

    link: IDiscoveryLink;
    service: BedsideService = new BedsideService();
    data: string = '';

    numberOfPages: number = 0;
    pageNumber: number = 1;
    scale: number = 1.3;

    constructor(link: IDiscoveryLink) {
        this.link = link;
        this._fetchData();

        makeObservable(this, {
            data: observable,
            numberOfPages: observable,
            pageNumber: observable,
            scale: observable
        });
    }

    getContentType = (): string => {
        switch (this.link.document.type) {
            case APPLICATION_ACROBAT:
                return APPLICATION_PDF;
            default:
                return this.link.document.type;
        }
    }

    onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        runInAction(() => {
            this.numberOfPages = numPages;
            this.pageNumber = 1;
        });
    }

    previousPage = () => {
        if (this.pageNumber > 1) {
            runInAction(() => {
                this.pageNumber -= 1;
            });
        }
    }

    nextPage = () => {
        if (this.pageNumber < this.numberOfPages) {
            runInAction(() => {
                this.pageNumber += 1;
            })
        }
    }

    private _fetchData = async (): Promise<void> => {
        const document = await this.service.getFile(`content-assets/${this.link.document.data.url}`);
        const base64 = btoa(
            new Uint8Array(document).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
            ),
        );

        runInAction(() => {
            switch (this.link.document.type) {
                case APPLICATION_ACROBAT:
                case APPLICATION_PDF:
                    this.data = `data:application/pdf;base64,${base64}`;
                    break;
                default:
                    this.data = `data:${this.link.document.type};base64,${base64}`;
            }
        });
    }
}
