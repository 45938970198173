import { Entertainment } from './Entertainment';
import { Information } from './Information';
import { IntroPage } from './IntroPage';
import { Video } from './Video';

const routes = [
    {
        path: '/',
        Component: IntroPage
    },
    {
        path: '/information',
        Component: Information
    },
    {
        path: '/entertainment',
        Component: Entertainment
    },
    {
        path: '/television',
        Component: Video
    }
];

export default routes;