export interface AppSubstrateRequest {
    method: 'POST' | 'GET'
    mode: RequestMode
    redirect: RequestRedirect
    cache: RequestCache
    headers: {
        'Cache-Control': string
        'X-AppSubstrate-ClientVersion': string
    }
    body: string
}

export interface AppSubstrateConfig {
    url: string;
    clientAccount: string;
    clientBrand: string;
    clientSubBrand: string;
    version: string;
}

export type StringIndexedObject = {
    [key: string]: string
}

export type AppSubstrateAuthHeader = {
    user: string;
    pass: string;
}

// TODO: move to constants
export const PAYLOADTYPE_JSON = 'json';
export const PAYLOADTYPE_PROTOBUF = 'protobuf';

export const RESPONSE_CONTAINER = 'container';
export const RESPONSE_ARRAYBUFFER = 'arrayBuffer';
export const RESPONSE_PROTOBUF = 'protobuf';

export const KEY_USER = 'userkey';
export const KEY_SERVER = 'serverkey';