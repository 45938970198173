import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DiscoveryModel } from 'models';
import { DiscoveryCategory, DiscoveryItem } from 'store';
import { CountlySingleton } from 'services';

const DiscoveryViewModel = (containerName: string) => {
    const model = DiscoveryModel();
    const history = useHistory();
    const [structure, setStructure] = useState<DiscoveryCategory[] | undefined>();
    const [searchQuery, setSearchQuery] = useState<string>('');

    const [activeLink, setActiveLink] = useState<IDiscoveryLink>();

    useEffect(() => {
        if (!structure || !structure.length) {
            (async () => {
                const structure = await model.getStructure(containerName);
                setStructure(structure!);
            })();
        }
    }, [containerName, model, structure]);

    const registerEvent = (item: DiscoveryItem) => {
        const key = CountlySingleton.shared.assessKey(containerName);
        CountlySingleton.shared.registerEvent(key, item.link.internal_title);
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    }

    const getContainerStructure = () => {
        if (structure) {
            const struct = structure!.map((struct) =>
                Object.assign(Object.create(Object.getPrototypeOf(struct)), struct)
            );
            const validated = validate(struct);
            const filtered = filter(validated);

            return filtered ?? [];
        }
    }

    const redirect = async (link: IDiscoveryLink) => {
        switch (link.type) {
            case 'website':
            case 'app':
                window.open(link.web_url!, '_blank')?.focus();
                break;

            case 'internal_app':
                // internal app url always prepended with :/ ??
                history.push({
                    pathname: link.internal_app_url!.substring(2),
                    state: { currentPath: 4, previousPath: 3 }
                });
                break;

            case 'document':
                setActiveLink(link);
                break;

            default:
                return '';
        }
    }

    const validate = (structure: DiscoveryCategory[]) => {
        const validated = structure.map((struct) => {
            struct.items = struct.items.filter((item) => {
                switch (item.link.type) {
                    case 'website':
                    case 'app':
                    case 'internal_app':
                        return item.link.internal_app_url || item.link.web_url;

                    case 'document':
                        return item.link.document && item.link.document.data.url;

                    default:
                        return false;
                }
            });

            return struct.items.length ? struct : null;
        }).filter(v => v);

        return validated as DiscoveryCategory[];
    }

    const filter = (structure: DiscoveryCategory[]) => {
        const query = searchQuery.toLowerCase();

        const filtered = structure.map((struct) => {
            struct.items = struct.items.filter((item) => item.getTitle().toLowerCase().includes(query) || struct.getTitle().toLowerCase().includes(query));

            return struct.items.length ? struct : null;
        }).filter(v => v);

        return filtered as DiscoveryCategory[];
    }

    return {
        searchQuery,
        activeLink,
        setActiveLink,
        registerEvent,
        handleSearch,
        getContainerStructure,
        redirect,
    }
}

export default DiscoveryViewModel;