import { useHistory, useLocation } from 'react-router';
import { useAssets } from 'context';
import { iAppModel } from 'models';
import { T } from 'localization';

const NavigationViewModel = (model: iAppModel) => {
    const assets = useAssets();
    const history = useHistory();
    const location = useLocation();
    const config = model.getConfig();

    const getHeader = () => {
        return assets.header;
    }

    const getPathMap = () => {
        const defaultContainers = ['information', 'entertainment'];
        const pathMap = (config.Bedside?.enabledContainers ?? defaultContainers).map((container) => `/${container}`);
        return ['/', ...pathMap];
    }

    const handleTabClick = (pathname: string) => {
        const pathMap = getPathMap();

        if (pathMap.indexOf(location.pathname) === pathMap.indexOf(pathname)) {
            return;
        }

        history.push({
            pathname,
            state: { currentPath: pathMap.indexOf(pathname), previousPath: pathMap.indexOf(location.pathname) }
        });
    }

    const getIconForPath = (pathname: string) => {
        switch (pathname) {
            case '/':
                return 'house';
            case '/information':
                return 'book';
            case '/entertainment':
                return 'dice';
            case '/television':
                return 'television';
        }
    }

    const getTitleForPath = (pathname: string) => {
        switch (pathname) {
            case '/':
                return T('tab.start');
            case '/information':
                return T('tab.information');
            case '/entertainment':
                return T('tab.entertainment');
            case '/television':
                return 'Video';
        }
    }

    const getLanguageCodes = () => {
        return config.Bedside?.enabledLanguageCodes ?? [];
    }

    return {
        location,
        config,
        getHeader,
        getPathMap,
        handleTabClick,
        getIconForPath,
        getTitleForPath,
        getLanguageCodes
    }
}

export default NavigationViewModel;