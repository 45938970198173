import { FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { observer } from 'mobx-react-lite';

import { DocumentViewModel } from '../viewModel';

import { Spinner } from 'framework';

import icons from 'assets/svg/bf-icons.svg';

import './Document.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFView: FC<{ viewModel: DocumentViewModel }> = observer(({ viewModel }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <svg className={`document-button`} onClick={() => viewModel.scale /= 1.1}><use xlinkHref={`${icons}#minus`} /></svg>
                <div style={{ width: 5 }} />
                <svg className={`document-button`} onClick={() => viewModel.scale *= 1.1}><use xlinkHref={`${icons}#plus`} /></svg>
            </div>

            <div className='document-page'>
                <Document
                    file={viewModel.data}
                    onLoadSuccess={viewModel.onDocumentLoadSuccess}
                    loading={<div className='center' style={{ padding: 20 }}><Spinner /></div>}
                    noData={''}
                >
                    <Page pageNumber={viewModel.pageNumber} scale={viewModel.scale} />
                </Document>
            </div>

            <div className='space-between align-center'>
                <svg className={`document-button ${viewModel.pageNumber <= 1 ? 'disabled' : ''}`} onClick={viewModel.previousPage}><use xlinkHref={`${icons}#chevron-left-small`} /></svg>
                <p>{viewModel.pageNumber || (viewModel.numberOfPages ? 1 : '--')}/{viewModel.numberOfPages || '--'}</p>
                <svg className={`document-button ${viewModel.pageNumber >= viewModel.numberOfPages ? 'disabled' : ''}`} onClick={viewModel.nextPage}><use xlinkHref={`${icons}#chevron-right-small`} /></svg>
            </div>

        </div>
    );
})

export default PDFView;
