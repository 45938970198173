import React from 'react';

import '../Tab.scss';

type Props = {
    children: React.ReactNode[],
};

const TabBar = ({ children }: Props) => {

    return (
        
        <footer>
            
            { children }

        </footer>
    )
}

export default TabBar