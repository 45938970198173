import { createContext, FC, useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { DiscoveryCategory } from 'store'

class Store {
    containerRequestPending: boolean = false;
    containers: { [identifier: string]: DiscoveryCategory[] | undefined } = {};

    constructor() {
        makeAutoObservable(this);
    }

    addContainer(containerName: string, container: DiscoveryCategory[]) {
        this.containers = { ...this.containers, [containerName]: container };
    }
}

const StoreContext = createContext<Store>(new Store());

const StoreProvider: FC<{ store: Store }> = ({ store, children }) => {
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

const useStore = () => {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}

const singleton = new Store();

export { Store, StoreProvider, useStore, singleton };