import React, { useState, createRef, useEffect, FC } from 'react';

import '../Dropdown.scss';

type Props = {
    label: any;
    className?: string;
    animation?: any;
}

const DropdownMenu: FC<Props> = ({ children, label, className, animation }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const node = createRef<HTMLDivElement>();

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (e: { target: any; }) => {
        const current = node.current;
        if (current!.contains(e.target)) {
            return;
        }
        setAnchorEl(null);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    });

    return (
        <div ref={node} className={className ? 'dropdown-container ' + className : 'dropdown-container'}
            onClick={open ? handleClose : handleMenu}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='dropdown-label'>
                    {label}
                </div>
                <div className='dropdown-icon'>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
            </div>
            <ul className={open ? 'dropdown-select open' : 'dropdown-select'}>
                {children}
            </ul>
            {animation}
        </div>
    );
}


export default DropdownMenu;
